//create a funtion component for react component with a image and a title

import React, { useEffect, useState } from 'react';
import { faMusic } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AudioPlayer from '../../components/AudioPlayer/AudioPlayer';


import style from "./Audio.module.scss";
import Button from "../../components/Button/Button";
import MenuRiver from '../../components/MenuRiver/MenuRiver';
import video from "../../assets/videos/video1.mp4"

const Audio = ({data}) => {


  const [isPlaying, setIsPlaying] = useState(false);

  const handleToggleMuted = () => {
    setMuted(!muted);
  }

  const [muted, setMuted] = useState(false);


  useEffect(() => {
  }, []);

  const toPlay = () => {
    setIsPlaying(!isPlaying);
  };

  const mediaMatch = window.matchMedia('(max-width: 880px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  return (
    <div className={style.root}
      style={{
        backgroundImage: `url(${data.imgbackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'relative',
        width: '100%',
        height: matches ? '200vw': '50vw',

      }}>
      <video className={style.video} style={{
        position: 'absolute',
        height: '50vw',
        zIndex: '0',
      }} src={video}
        autoplay="true" muted="true" loop="true"
      ></video>
      <div className={style.body}>
        <div className={style.text}>
          <div className={style.titleCron}>
            <h2>{data.title}</h2>
          </div>
          <div className={style.content}>
            <p>
              {data.content}
            </p>
          </div>
          <div className={style.audio}>
            <div onClick={handleToggleMuted}  className={style.audioStick}>
              SONIDO
              <div className={style.pipe}></div>
              <FontAwesomeIcon icon={faMusic} />
            </div>
          </div>
          <div className={style.player}>
            <AudioPlayer
              //ref={audioRef}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              muted={muted}
              src={data.audio}>
            </AudioPlayer>
          </div>
        </div>
      </div>
      <div>
        <Button to={data.nextindex} text={data.txtB}></Button>
      </div>
    </div>
  );
};

export default Audio;