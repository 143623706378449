//create a funtion component for react component with a image and a title

import React, { useEffect, useState } from 'react';
import { faHeadphones } from '@fortawesome/free-solid-svg-icons';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AudioPlayer from '../../components/AudioPlayer/AudioPlayer';
import ReactPlayer from 'react-player';


import style from "./Cronica.module.scss";
import Button from "../../components/Button/Button";

const Cronica = ({ data }) => {


  const [isPlaying, setIsPlaying] = useState(false);


  useEffect(() => {
  }, []);

  const toPlay = () => {
    setIsPlaying(!isPlaying);
  };

  const mediaMatch = window.matchMedia('(max-width: 880px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  return (
    <div className={style.root}
      style={{
        backgroundImage: `url(${data.imgbackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'relative',
        width: '100%',
        height: matches ? '200vw': '50vw',
      }}>
      <div className={style.body}>
        <div className={style.text}>
          <img src={data.logo} className={style.logo}></img>
          <div className={style.titleCron}>
            <div>{data.title}</div>
          </div>
          <div className={style.content}>
            <p>
              {data.content}
            </p>
          </div>
          <div className={style.audio}>
            <FontAwesomeIcon icon={faHeadphones} />
            <div className={style.audioStick}>
              Escuchar Podcast
            </div>
          </div>
          <div className={style.player}>
            <AudioPlayer
              //ref={audioRef}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              src={data.audio}>
            </AudioPlayer>
          </div>
        </div>
      </div>
      <div className={style.footer}>
        <Button to={data.nextindex} text={data.txtB}></Button>
      </div>
    </div>
  );
};

export default Cronica;