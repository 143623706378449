import { Link } from "react-scroll";
import style from "./MenuRiver.module.scss";
import river from "../../assets/images/svg/menu_flotante.svg";
import logo from "../../assets/images/png/logos/Logoverde.png";
import ModalRedes from "../ModalRedes/ModalRedes";

const MenuRiver = ({ to, text }) => {
  return (
    <div className={style.root}>
      <Link
        to={"videof1"}
        activeClass="active"
        smooth={true}
        duration={1500}
        className={style.capitulo}
      >
      <div className={style.intro}>INTRO</div>  
      </Link>
      <div className={style.title}>CAPÍTULOS</div>
      <img src={river} className={style.river}></img>
      <div className={style.brown}>EN REDES</div>
      <div className={style.capitulosText}>
        <Link
          to={"cap1"}
          activeClass="active"
          smooth={true}
          duration={1500}
          className={style.capitulo}
        >
          1. Origen
        </Link>
        <Link
          to={"cap2"}
          activeClass="active"
          smooth={true}
          duration={1500}
          className={style.capitulo}
        >
          2. Indígenas
        </Link>
        <Link
          to={"cap3"}
          activeClass="active"
          smooth={true}
          duration={1500}
          className={style.capitulo}
        >
          3. Explotación
        </Link>
        <Link
          to={"cap4"}
          activeClass="active"
          smooth={true}
          duration={1500}
          className={style.capitulo}
        >
          4. Ciudades
        </Link>
        <Link
          to={"cap5"}
          activeClass="active"
          smooth={true}
          duration={1500}
          className={style.capitulo}
        >
          5. Gobernanza
        </Link>
        <Link
          to={"cap6"}
          activeClass="active"
          smooth={true}
          duration={1500}
          className={style.capitulo}
        >
          6. Compromisos
        </Link>
      </div>
      <ModalRedes logo={logo}></ModalRedes>
    </div>
  );
};

export default MenuRiver;
