//create a funtion component for react component with a image and a title

import React, { useEffect, useState } from "react";
import { faBookOpen, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import style from "./CronicaR.module.scss";
import Button from "../../components/Button/Button";

const CronicaR = ({ data }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {}, []);

  const toPlay = () => {
    setIsPlaying(!isPlaying);
  };

  const mediaMatch = window.matchMedia("(max-width: 880px)");
  const [matches, setMatches] = useState(mediaMatch.matches);

  return (
    <div
      className={style.root}
      style={{
        backgroundImage: `url(${data.imgbackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "relative",
        width: "100%",
        height: matches ? "200vw" : "50vw",
        zIndex: data.zIndex ? data.zIndex : 0,
      }}
    >
      <div className={style.body}>
        <div className={style.text}>
          <div className={style.audioMuted}>
            {/*             <div className={style.audioStickM}>
              SONIDO
              <div className={style.pipe}></div>
              <FontAwesomeIcon icon={faMusic} />
            </div> */}
          </div>
          <div className={style.titleCron}>
            <h3>{data.title}</h3>
            <div className={style.sticks}>
              <div className={style.audio}>
                <FontAwesomeIcon icon={faBookOpen} />
                <div className={style.audioStick}>LEER</div>
              </div>
              {data.doc &&
              <a href={data.doc} target="__blank">
                <div className={style.audio} style={{marginLeft: 15}}>
                  <FontAwesomeIcon icon={faDownload} />
                  <div className={style.audioStick}>DESCARGAR</div>
                </div>
              </a>
              }
            </div>
          </div>
          <div className={style.content}>
            <h2>{data.subtitle}</h2>
            <p>{data.content}</p>
          </div>
        </div>
      </div>
      <div className={style.btn}>
        <Button to={data.nextindex} text={data.txtB}></Button>
      </div>
    </div>
  );
};

export default CronicaR;
