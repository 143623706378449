import React, { useState } from "react";

import style from "./ModalRedes.module.scss";
import Modal, { Styles } from 'react-modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Social from "../Social/Social";
import logoWhite from "../../assets/images/png/logos/blanco_logo.png";
import Instagram from "../InstagramEmbed/InstagramEmbed";
import TwitterEmbed from "../TwitterEmbed/TwitterEmbed";
//images for all content elements


const ModalRedes = ({ logo }) => {

    const [modalIsOpen, setIsOpen] = useState(false);


    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        setIsOpen(false);
    }


    const ModalStyles: Styles = {
        overlay: {
            zIndex: '100',
        }
    };

    const customModalStyles = {
        overlay: { ...ModalStyles.overlay }
    };

    return (
        <div >

            <div className={style.footer}
                onClick={openModal}
            >
                <div>
                    <img src={logo} className={style.logo}></img>
                </div>
            </div>

            <div className={style.parentModal}>
                <Modal
                    className={style.modal}
                    style={customModalStyles}
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    contentLabel="Redes Modal"
                >
                    <button className={style.closeModal} onClick={closeModal}>
                        <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
                    </button>
                    <div className={style.modalBody}>
                        <div className={style.row}>
                            <div className={style.column}>
                                <div className={style.brownModal}
                                >
                                    EN REDES
                                </div>
                                <div className={style.yellowModal}
                                >
                                    LO
                                    <br></br>
                                    QUE
                                    COMEN
                                    TAN
                                </div>
                                <img src={logoWhite}></img>
                                <Social color={'white'}></Social>
                            </div>
                            <div className={style.embeds}>
                                <Instagram></Instagram>
                                <TwitterEmbed></TwitterEmbed>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};



export default ModalRedes;
