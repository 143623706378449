import React, { Component } from "react";
import screenfull from "screenfull";
import ReactPlayer from "react-player";
import Duration from "./Duration";

import style from "./VideoPlayer.module.scss";
import {
  faVolumeHigh,
  faVolumeLow,
  faVolumeOff,
  faPlay,
  faPause,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyContext from "../../context/context";

class VideoPlayer extends Component {
  state = {
    url: null,
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    sound: false,
    started: false,
    paused: this.context.contextPause,
  };

  static contextType = MyContext;

  componentDidUpdate() {
    if (this.context.contextPause !== false) {
      if (this.context.contextPause === this) {
        this.setState({ playing: true });
      } else {
        this.setState({ playing: false });
      }
      this.context.setContextPause(false);
    }
  }

  handleGlobalPause = () => {
    this.context.setContextPause(this);
  };

  load = (url) => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false,
    });
  };

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  handleStop = () => {
    this.setState({ url: null, playing: false });
  };

  handleToggleControls = () => {
    const url = this.state.url;
    this.setState(
      {
        controls: !this.state.controls,
        url: null,
      },
      () => this.load(url)
    );
  };

  handleToggleLight = () => {
    this.setState({ light: !this.state.light });
  };

  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop });
  };

  handleVolumeChange = (e) => {
    this.setState({ volume: parseFloat(e.target.value) });
  };

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  handleSetPlaybackRate = (e) => {
    this.setState({ playbackRate: parseFloat(e.target.value) });
  };

  handleOnPlaybackRateChange = (speed) => {
    this.setState({ playbackRate: parseFloat(speed) });
  };

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip });
  };

  handlePlay = () => {
    //console.log('onPlay')
    this.setState({ playing: true });
    this.setState({ started: true });
    this.handleGlobalPause();
  };

  handleEnablePIP = () => {
    //console.log('onEnablePIP')
    this.setState({ pip: true });
  };

  handleDisablePIP = () => {
    //console.log('onDisablePIP')
    this.setState({ pip: false });
  };

  handlePause = () => {
    //console.log('onPause')
    this.setState({ playing: false });
  };

  handleSeekMouseDown = (e) => {
    this.setState({ seeking: true });
  };

  handleSeekChange = (e) => {
    this.setState({ played: parseFloat(e.target.value) });
  };

  handleSeekMouseUp = (e) => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
  };

  handleProgress = (state) => {
    //console.log('onProgress', state)
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state);
    }
  };

  handleEnded = () => {
    //console.log('onEnded')
    this.setState({ playing: this.state.loop });
  };
  handleSound = () => {
    //console.log('sound: this.state.sound',)
    this.setState({ sound: !this.state.sound });
    //console.log('click sound: this.state.sound',)
  };
  handleDuration = (duration) => {
    //console.log('onDuration', duration)
    this.setState({ duration });
  };

  handleClickFullscreen = () => {
    screenfull.request(document.querySelector(".react-player"));
  };

  renderLoadButton = (url, label) => {
    return <button onClick={() => this.load(url)}>{label}</button>;
  };

  ref = (player) => {
    this.player = player;
  };

  render() {
    const {
      url,
      playing,
      controls,
      light,
      volume,
      muted,
      loop,
      played,
      loaded,
      duration,
      playbackRate,
      pip,
    } = this.state;
    const { video, imgpre, text } = this.props;
    const SEPARATOR = " · ";

    return (
      <div className={style.videoplayer}>
        <div
          className={style.preload}
          style={{ display: this.state.started ? "none" : "flex" }}
        >
          {text && (
            <div className={style.text}>
              <p>{text}</p>
            </div>
          )}
          <button onClick={this.handlePlay}>
            <FontAwesomeIcon icon={faPlay} />
          </button>
        </div>
        <div className={style.videoplayercontainer}>
          <ReactPlayer
            ref={this.ref}
            className="react-player"
            width="100%"
            height="100%"
            pip={pip}
            playing={playing}
            controls={controls}
            light={light}
            loop={loop}
            playbackRate={playbackRate}
            volume={volume}
            muted={muted}
            //onReady={() => console.log('onReady')}
            //onStart={() => console.log('onStart')}
            onPlay={this.handlePlay}
            onEnablePIP={this.handleEnablePIP}
            onDisablePIP={this.handleDisablePIP}
            onPause={this.handlePause}
            //onBuffer={() => console.log('onBuffer')}
            onPlaybackRateChange={this.handleOnPlaybackRateChange}
            //onSeek={e => console.log('onSeek', e)}
            onEnded={this.handleEnded}
            //onError={e => console.log('onError', e)}
            onProgress={this.handleProgress}
            onDuration={this.handleDuration}
            //onPlaybackQualityChange={e => console.log('onPlaybackQualityChange', e)}
            url={video}
            config={{
              file: {
                attributes: {
                  src: video,
                },
              },
            }}
          />
        </div>
        <div
          className={style.videoplayercontrols}
          style={{ display: this.state.started ? "flex" : "none" }}
        >
          <div>
            <button onClick={this.handlePlayPause}>
              {playing ? (
                <FontAwesomeIcon icon={faPause} />
              ) : (
                <FontAwesomeIcon icon={faPlay} />
              )}
            </button>
          </div>
          <div>
            <div className={style.Duration}>
              <Duration seconds={duration * played} />
            </div>
          </div>
          <div className={style.SeekBar}>
            <input
              type="range"
              min={0}
              max={0.999999}
              step="any"
              value={played}
              onMouseDown={this.handleSeekMouseDown}
              onChange={this.handleSeekChange}
              onMouseUp={this.handleSeekMouseUp}
            />
          </div>
          <div className={style.VolumenCont}>
            <div
              className={style.Volumen}
              style={{ display: this.state.sound ? "flex" : "none" }}
            >
              <input
                type="range"
                min={0}
                max={1}
                step="any"
                value={volume}
                onChange={this.handleVolumeChange}
              />
            </div>
            <button onClick={this.handleSound}>
              <FontAwesomeIcon icon={faVolumeHigh} />
            </button>
          </div>
          <div>
            <div>
              <button id="muted" onClick={this.handleToggleMuted}>
                <FontAwesomeIcon icon={faVolumeOff} />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoPlayer;
