import cap1 from "../assets/images/fotos_/AMAZONIA-ORIGEN9.JPG";
import cap2 from "../assets/images/images_fondos/PUEBLOSINDIGENAS60.png";
import cron1 from "../assets/images/fotos_/fondorelleno.jpeg";


import mult4 from "../assets/images/fotos_/mult1.jpeg";

import imgsimple1 from "../assets/images/images_fondos/3-MILCIADES2.jpg";
import imgorigen from "../assets/images/images_fondos/AMAZONIA-ORIGEN15.png";
import imghistoria from "../assets/images/images_fondos//origen1.jpg";
import delfin from "../assets/images/images_fondos/Fondodelfinweb.jpg";
import juris1 from "../assets/images/images_fondos/cap5/GOBERNANZAINDIGENA3.png";
import juris2 from "../assets/images/images_fondos/cap5/GOBERNANZAINDIGENA4.png";
import juris3 from "../assets/images/images_fondos/cap5/GOBERNANZAINDIGENA6.png";

import arbol from "../assets/images/images_fondos/5-El-Arbol-que-Llora.png";
import hablar from "../assets/images/images_fondos/2.-Hablar-la-selva.png";
import culture from "../assets/images/images_fondos/PAISAJESONORO-ComoseoyelaAmazonia01.jpg";
import pesca from "../assets/images/images_fondos/PUEBLOSINDIGENAS62.png";

import habselva from "../assets/images/images_fondos/3.Habitarlaselva.png";
import explot from "../assets/images/images_fondos/EXPLOTACION17.png";
import explot6 from "../assets/images/images_fondos/EXPLOTACION6.png";

import ciudad1 from "../assets/images/images_fondos/CIUDADES1.png";
import ciudad2 from "../assets/images/images_fondos/CIUDADES10.png";
import ciudad3 from "../assets/images/images_fondos/CIUDADES13.png";
import ciudad4 from "../assets/images/images_fondos/CIUDADES2.png";

import sol1 from "../assets/images/images_fondos/cap6/SOLUCIONCOMPARTIDA3.png"
import sol2 from "../assets/images/images_fondos/cap6/SOLUCIONCOMPARTIDA5.png"
import sol3 from "../assets/images/images_fondos/cap6/SOLUCIONCOMPARTIDA11.png"
import sol4 from "../assets/images/images_fondos/cap6/Jairo-diamante-de-las-Aguas.jpg"

//videos
import video1 from "../assets/videos/video1.mp4";
//import videoprom1 from "../assets/videos/1- Llegada_Débora_Amazonas.mp4";
import videofullpeligro from "../assets/videos/4-VIDEOLOOP- CONTEXTODELTEMA.FRAGMENTOINTRONIKO.mp4";
import videofull6 from "../assets/videos/6-Video Loop INICIA L VIAJE2.mp4";
import videofull7 from "../assets/videos/2-VIDEO Loops SONIDOS DEL AMAZONAS.mp4";
import videopedag1 from "../assets/videos/4 Video Pedagogico Niko.mp4";

import video71 from "../assets/videos/7- 1-RIOS VOLADORES.mp4";
import video72 from "../assets/videos/7- 2-DOS TIPOS DE RIOS.mp4";
//import video73 from "../assets/videos/7 -3-Capsula Ríos como autopistas web.mp4";

//import videohormigas from "../assets/videos/3_Hormigas Arrieras.mp4";
import videovanessa from "../assets/videos/10. VIDEO LOOP - VANESSA -- CIERRE.mp4";
import videoinfobiomas from "../assets/videos/12. VIDEO - INFOGRAFÍA -- CÁPSULA BIOMAS..mp4";

import videoagustin from "../assets/videos/2 VIDEOLOOP AGUSTIN CANTOr.mp4";
import videovida from "../assets/videos/3 Voz coral Amazoniar.mp4";
import videoinf2 from "../assets/videos/4 Infografias pueblos indigenas W_ Sonido.mp4";
//import videoley from "../assets/videos/7 Ley de Origen.mp4";

import videoex1 from "../assets/videos/A. INFOGRAFIA CONQUISTADORES.mp4";
import videoex2 from "../assets/videos/B. DOCU-PERFIL NOE CAMPESINO DEPREDADOR.mp4";
//import videoex3 from "../assets/videos/C. INFOGRAFIA PUNTOS DE EXPLOTACION.mp4";
import videoex4 from "../assets/videos/D. CAPSULA P. NO SOMOS DUEÑOS.mp4";
import videoex5 from "../assets/videos/E. RIOS DE MERCURIO.mp4";

//logos
import sclogo from "../assets/images/png/logos/senal_colombia_blan.png";
import smlogo from "../assets/images/png/logos/senal_memor.png";
import radional from "../assets/images/png/logos/radional_blan.png";

//docs

import bufeos from "../assets/docs/last/EL ENCANTO DEL BUFEO, LA LEYENDA QUE COBRÓ VIDA copia.pdf";
import cacaodoc from "../assets/docs/last/CACAO CON AROMA DE SELVA.pdf";
import arboleslloran from "../assets/docs/last/LA CHORRERA LOS ÁRBOLES QUE YA NO LLORAN copia (1).pdf";
import colegiodoc from "../assets/docs/last/LLEVANDO LOS NIÑOS AL COLEGIO… EN LA SELVA copia.pdf";
import puertonardoc from "../assets/docs/last/PUERTO NARIÑO, EN ARMONÍA CON LA TIERRA copia.pdf";
import diamantedoc from "../assets/docs/last/UN DIAMANTE DE LA CONSERVACIÓN.pdf";
import casadoc from "../assets/docs/last/EntrevistaRubén.pdf";



//audios

import audioorig from "../assets/audios/PODCAST - Importancia dentro del cambio climatico.mp3";
import audiosm2 from "../assets/audios/AMAZONAS_PODCAST _CAPITULO 2_HQMP3.mp3";
import audiocul2 from "../assets/audios/PAISAJE SONORO - Expresiones Culturales.mp3";
import audiosm3 from "../assets/audios/AMAZONAS_PODCAST _CAPITULO 3_HQMP3.mp3";
import audioasen from "../assets/audios/PODCAST-Llegada y asentamiento de las comunidades campesinas.mp3";

import ciudadpod1 from "../assets/audios/PODCAST - Regulador de la temperatura para las ciudades.mp3";
import ciudadpod2 from "../assets/audios/AMAZONAS_PODCAST _CAPITULO 3_MITU.mp3";
import jurispod from "../assets/audios/PODCAST-ReconocimientodelajurisdiccionindigenaenConstitucionde1991.mp3";

const diana1 =
  "https://rtvcplay-v2.s3-accelerate.amazonaws.com/s3fs-public/field/file/audio/Pgm%20Diana%20Uribe%205%20Viaje%20Hacia%20La%20Amazonia.mp3";
const diana2 =
  "https://rtvcplay-v2.s3-accelerate.amazonaws.com/s3fs-public/field/file/audio/Festival%20de%20Pirarucu%CC%81%20.mp3";

  const data = [
  {
    cap: 0,
    title: "Intro",
    content:[
      {
        type: "videofull",
        titleh: "La amazonía es mujer, la amazonia soy yo",
        title: "La amazonía es mujer, la amazonia soy yo",
        video: video1,
        txtB: "seguir explorando",
        index: "videof1",
        imgbackground: cron1,
        nextindex: "crons1",
        zIndex: 99,
      },
      {
        type: "cronsimple",
        titleh: "UNA EXPEDICIÓN POR LA VIDA",
        title: "UNA EXPEDICIÓN POR LA VIDA",
        subtitle: "",
        content: `"Después de la baletería y la cauchería, los golpes a la Amazonía fueron por la extracción del oro, las pieles de los tigres, las plumas de garza y guacamayas… “barrieron con esto y quedamos nosotros. El exterminio vino a parar hace relativamente poco…las potencias mundiales han entrado como ‘Pedro por su casa’ a explotar los recursos de la Amazonía, se los han llevado para lucrarse y pocos hicieron algo. Nosotros nunca despertamos y este es el pueblo más olvidado por Colombia”.\n
        MILCIADES BORRERO GUANANA,\n Docente - Historiador.\n Mitú, Putumayo.`,
        index: "crons1",
        nextindex: "videofullpel",
        txtB: "seguir explorando",
        imgbackground: imgsimple1,
        zIndex: 99,
      },
      {
        type: "videofull",
        titleh: "La amazonía está en peligro",
        title: "La amazonía está en peligro",
        video: videofullpeligro,
        txtB: "seguir explorando",
        index: "videofullpel",
        imgbackground: cron1,
        nextindex: "docu1",
        zIndex: 99,
      },
      {
        type: "video",
        titleh: "Débora se va de Viaje",
        title: "Débora llega al amazonas",
        logo: sclogo,
        video: "https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:13170424.smil/playlist.m3u8",
        imgpre: mult4,
        txtB: "seguir explorando",
        index: "docu1",
        nextindex: "videofullexpl",
        zIndex: 99,
      },
      {
        type: "videofull",
        titleh: "Una expedición por la vida",
        title: "Una expedición por la vida",
        video: videofull6,
        txtB: "seguir explorando",
        index: "videofullexpl",
        imgbackground: cron1,
        nextindex: "cap1",
        zIndex: 99,
      },
    ]
  },
  {
    cap: 1,
    title: "El Origen",
    content: [
      {
        type: "titlecap",
        cap: "1",
        titleh: '"EL ORIGEN"',
        title: '"EL ORIGEN"',
        content: "La Amazonía no es solo el departamento del Amazonas al sur de Colombia. Es el hogar de gran cantidad de especies de flora y fauna que conviven con comunidades indígenas y campesinas, y se relacionan en un territorio que abarca siete millones de kilómetros cuadrados, en una amplia región de Suramérica, similar a la extensión de un país como Australia. ",
        imgbackground: cap1,
        subtitle:
          "Amazonia. un mundo de relaciones",
        index: "cap1",
      },
      {
        type: "videofull",
        titleh: "Esto es la Amazonía",
        title:
          "La Amazonía tiene casi el 10 por ciento de la fauna y flora de la Tierra.",
        video: videofull7,
        txtB: "seguir explorando",
        index: "videofullesto",
        imgbackground: cron1,
        nextindex: "crona1",
      },
      {
        type: "cronaudio",
        titleh: "HISTORIA DE LA AMAZONÍA",
        title: "HISTORIA DE LA AMAZONÍA",
        logo: smlogo,
        content: ``,
        index: "crona1",
        nextindex: "videoconoce",
        txtB: "seguir explorando",
        imgbackground: imghistoria,
        audio: diana1,
      },
      {
        type: "video",
        titleh: "Conoce la Amazonía",
        title: "Conoce la Amazonía",
        text: "Con selvas y ríos inmensos y repletos de una naturaleza única que sustenta una diversidad cultural notable en 7 millones de kilómetros cuadrados, la Amazonía es de una importancia extraordinaria para Sudamérica y para todo el planeta.",
        logo: sclogo,
        video: videopedag1,
        txtB: "seguir explorando",
        index: "videoconoce",
        nextindex: "cronr1",
      },
      ,
      {
        type: "cronreading",
        titleh: "EL ENCANTO DE LOS BUFEOS",
        title: "EL ENCANTO DE LOS BUFEOS",
        subtitle: "EL MISTERIO DEL DELFÍN ENAMORADO",
        content: `
        La selva de la Amazonía alberga secretos en cada hoja, cada árbol, río, sendero o montaña. Las tribus han aprendido a comunicarse con su entorno y de cuando en vez, la selva y sus habitantes se manifiestan en los sueños o hasta toman formas para acercarse a los humanos.
        Los relatos indígenas están nutridos de lo que el hombre blanco define como mitos y leyendas, como casi siempre cuando le cuesta aceptar algo que no puede explicar. Pero quienes viven allí las han sentido de cerca y ese es parte del misterio que rodea la selva.
        Soraina Lulate y su esposo Luis Hernando Peña dan fe de eso. Ella se presenta como “viviente de Santa Clara de Tarapoto”, una comunidad a orillas del lago del mismo nombre, a media hora de Puerto Nariño, departamento del Amazonas. Ella asegura ser testigo de cómo un delfín rosado puede transformarse para seguir a una persona de la que se siente enamorado. Una de esas personas fue su esposo Luis Hernando Peña. 
        Tiene 31 años y lo conoció hace 15 porque él es hijo del fundador de la comunidad, que ya murió. “Me vio y se quedó conmigo y hoy tenemos cuatro hijos”, cuenta. Pero la historia empezó cuando Luis era soltero. Le contó que desde joven lo perseguía una tonina, como llaman a los delfines. Su padre ya no estaba y él vivía con su madre y acostumbraba a irse a Puerto Nariño a tomar cerveza.
        “Decía que siempre que tomaba, los bufeos -delfines- lo seguían y no sabía por qué. Entonces se hizo mirar de un chamán que le dijo que el que era una hembra que estaba enamorada de él”. Luis le preguntó al abuelo cómo podía evitar esto y el sacerdote le dio una protección para que se la colocara siempre que fuera a beber al pueblo y en un ritual le incorporó viento y lo rezó.
        Pasó el tiempo y Soraina llegó a la comunidad y un día el bufeo volvió a seguirlo, él le contó “mire mi amor, no sé por qué los bufeos me siguen”, pero ella no le creía. Pero recordó que siendo pequeña sus abuelos y papás le habían dicho que cuando un delfín sigue a una persona hay que usar un copal, una especie de piedra que se derrite y se unta en el cuerpo como protección para ir a la selva y que los animales no se acerquen. “Cuando vayas a tener marido esta experiencia vas a llevar”, le dijeron.
        Amores tormentosos
        Cuando las toninas aparecían, él les tiraba un pedacito de copal al agua y se alejaban, pero un día no resultó. “El bufeo no se aguantó, se sentía muy enamorado, yo me había ido a la comunidad del 12 de octubre donde mi hermano a buscar unos plátanos que me iba a regalar porque no tenía qué comer y le dije a mi esposo que se quedara a cuidar los niños, pero él se puso rebelde conmigo y se fue a beber”, recuerda Soraina.
        A eso de las siete de la noche volvió a Santa Clara y subió a la maloka para pedirle a Luis que fuera por unos plátanos que había en la canoa, pero uno de sus hijos le contó que él había llegado ebrio, entonces ella entró y le dijo que en el bote había algo para subir y se dispuso a hacer la cena. “Como a las nueve de la noche lo llamé para que subiera a cenar, pero no hizo caso”. Luis se quedó afuera de la maloka, en frente de la cual hay una cancha de fútbol de la comunidad, luego un parte sin yerba y el lago. Lo volvió a llamar, pero no quiso ir, entonces Soraina cenó y le guardó.
        “Eran como las 10 de la noche cuando escuché los perros ladrar sin descanso, salí a mirar y vi a una persona que no conocía caminar desde el puerto. Me quedé observándola, subió lento hasta la cancha mientras Luis Hernando permanecía dormido afuera”.
        Pensó en el bufeo y las historias que dicen que, para atraparlo, se debe rodear para que caiga, pero prefirió despertar a su marido. Le decía que subieran, pero él no hacía caso y ella no quiso decirle lo que estaba viendo, lo haló y como pudo lo llevé hasta la sala cerca de la entrada, ella se entró y trancó la puerta. “Quise gritar para avisar al hermano, pero la voz no me salió, quedé muda”.
        De repente uno de los perros corrió hacia la persona y esta huyó hacia el río y brincó al agua. Quedó estupefacta: “Ahora sé que era un bufeo que quería robar al papá de mis hijos”, dice en un español forzado. Pero en ese momento prefirió ignorar el tema y se acostó, sin embargo, se sintió inquieta por su marido que seguía en la entrada. Entonces los perros volvieron a ladrar y se asomó. Allí estaba de nuevo, de pie en la cancha de fútbol, observando a Luis Hernando.
        “Salí y lo volví a sacudir, pero él parecía muerto, no reaccionaba y yo no podía gritar”. De pronto el bufeo dio dos pasos y llegó a la escalera y sin saber cómo se armó de valor y le salió al paso, entonces la entidad volvió a correr y se tiró al agua, “pero ahora vi que había otros delfines”.
        “Bañé a mi marido y le decía que dormía mucho, le ayudé a cambiarse y le reconocí lo que había visto. “Ahora lo he visto con mis propios ojos, pero vamos a hacer una cura. Cogí el copal que me había dado mi papá, lo machuqué y se lo unté a Luis Hernando en el cuerpo”.
        Al día siguiente ella habló con Luis del bufeo que lo había seguido, que tenía aspecto de una mujer, pero que ella había sido fuerte y no se lo llevó. “Él se levantó y se asustó mucho porque me dijo que días antes soñó que una mujer subía a la maloka donde estaba y se lo iba a llevar. Era esa, una sirena hembra, dicen que parece una persona y se puede ver también de día. Nosotros la vimos”.
        No lo volvieron a seguir hasta que Soraina dejó de untarle el copal y una noche estaba en el pueblo y vio sentada a su lado a una mujer que parecía extranjera y lo veía de pies a cabeza. Luis contó que le llamó la atención su vestimenta como en capas y buscó su copal, pero lo había olvidado en la maloka. Entonces decidió no regresar solo al lago, llamó a un cuñado para que lo acompañara y cuando iban a medio camino Luis vio un delfín boyando cerca y se lo señaló a su cuñado. “Eso es lo que me sigue, es una sirena”.
        Soraina los vio llegar y de nuevo se repitió todo, pudo dar aviso, pero su hermano salió y le dijo que no gritara, buscó una escopeta y bajó a perseguir al bufeo, pero esta vez salió de la cancha por otro lado y saltó desde una balsa al agua.
        Nunca más su esposo ha dejado de untarse el copal, los bufeos no han vuelto a aparecérsele en sus travesías desde Puerto Nariño. Dice Soraina que, aunque el delfín es visto como un espíritu bueno por los indígenas, como todo, también puede tener su lado malo.
        “Los ancestros contaban que cuando uno de ellos se sentía muy enamorado de una persona no dejaban de seguirla para raptarla y llevarla a su comunidad en el agua, donde se adapta y se queda. Jamás vuelve porque lo hipnotizan con otro pensamiento y si regresa las personas le producen miedo y se ve extraño a sí mismo”. 
        `,
        doc: bufeos,
        index: "cronr1",
        nextindex: "cronorigen1",
        txtB: "seguir explorando",
        imgbackground: delfin,
      },
      {
        type: "cronaudio",
        titleh: "EL ORIGEN, PODCAST",
        title: "EL ORIGEN",
        logo: radional,
        content: ``,
        index: "cronorigen1",
        nextindex: "videomulti1",
        txtB: "seguir explorando",
        imgbackground: imgorigen,
        audio: audioorig,
      },
      {
        type: "videomulti",
        titleh: "Descubre los ríos de la Amazonía",
        title: "Descubre los ríos de la Amazonía",
        text: "Muchas especies amazónicas, se guían, o a veces viajan por sus grandes autopistas que son los ríos, algunos científicos dicen que son como grandes vías que forman una red de comunicación.",
        video1: video71,
        video2: video72,
        video3: "https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:73170424.smil/playlist.m3u8",
        imgpre: "",
        txtB: "seguir explorando",
        index: "videomulti1",
        nextindex: "videoh",
      },
      {
        type: "video",
        titleh: "Débora y las Hormigas",
        title: "Débora y las Hormigas",
        text: "Jimmy Vásquez le cuenta a Débora acerca de las famosas hormigas arrieras que abundan en el Amazonas.",
        logo: sclogo,
        video: "https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:33170424.smil/playlist.m3u8",
        imgpre: mult4,
        txtB: "seguir explorando",
        index: "videoh",
        nextindex: "videov",
      },
      {
        type: "videofull",
        titleh: "LA AMAZONÍA ES UNA RESPONSABILIDAD DE TODOS.",
        title: "LA AMAZONÍA ES UNA RESPONSABILIDAD DE TODOS.",
        video: videovanessa,
        txtB: "seguir explorando",
        index: "videov",
        imgbackground: cron1,
        nextindex: "tip1",
      },
      {
        type: "tip",
        titleh: "Sabías que",
        title: "Sabías que",
        content1:
          "a) Los ríos de la Amazonía son vías de comunicación de la selva, por donde se movilizan comunidades indígenas y campesinas.",
        content2:
          "b) En la Amazonía hay varios tipos de ríos que influyen en la fauna y la flora de cada una de sus regiones. ",
        content3:
          "c) Cuando la Amazonía se inunda se convierte en una laguna inmensa y especies acuáticas recorren el bosque sembrando semillas.",
        index: "tip1",
      },
      {
        type: "video",
        titleh: "La Amazonia en el Planeta",
        title: "La Amazonia en el Planeta",
        logo: sclogo,
        video: videoinfobiomas,
        txtB: "seguir explorando",
        index: "videobiomas",
        nextindex: "cap2",
      },
    ],
  },
  {
    cap: 2,
    title: "Indígenas",
    content: [
      {
        type: "titlecap2",
        cap: "2",
        title: "PUEBLOS INDÍGENAS",
        titleh: "PUEBLOS INDÍGENAS",
        subtitle: "Espíritus de la naturaleza",
        content: "Las comunidades indígenas de la Amazonía no se consideran superiores a ninguna de las especies de flora y fauna, ni de las piedras, el agua o el aire. Sienten que son una parte más de la naturaleza y se relacionan con ella desde el respeto transmitido por sus ancestros.",
        imgbackground: cap2,
        pos: 0,
        index: "cap2",
      },
      {
        type: "videofull",
        titleh: "La Armonía",
        title: "La Armonía",
        video: videoagustin,
        txtB: "seguir explorando",
        index: "videof2",
        imgbackground: "",
        nextindex: "videovida2",
      },
      {
        type: "videofull",
        titleh: "Historias de vida",
        title: "Cada palabra es una historia de vida",
        video: videovida,
        txtB: "seguir explorando",
        index: "videovida2",
        imgbackground: "",
        nextindex: "videoinfo2",
      },
      {
        type: "video",
        titleh: "Una Casa Común Llamada Amazonía",
        title: "Una Casa Común Llamada Amazonía",
        logo: sclogo,
        video: videoinf2,
        txtB: "seguir explorando",
        index: "videoinfo2",
        nextindex: "cronr2",
      },
      {
        type: "cronreading",
        titleh: "El Árbol que Llora",
        title: "El Árbol que Llora",
        subtitle: "LA CHORRERA: LOS ÁRBOLES QUE YA NO LLORAN",
        doc: arboleslloran,
        content: `Los descendientes de aquellos que fueron descritos como “salvajes”, “antropófagos” e “irracionales”, quieren pasar la página de esa ignominia y de la aniquilación por la cauchería. Desenterraron sus recuerdos para sanar, aunque la memoria no traiciona.


            Cuando el mundo enterraba el siglo XIX y abría los ojos al siglo XX ya se tejía una de las más infames páginas en contra de las etnias indígenas de la Amazonía colombiana. Algunas décadas antes Charles Goodyear pregonaba que había descubierto el proceso de vulcanización para hacer el caucho más duro, aunque 3.500 años los Olmecas ya le habían dado uso.
            
            
            Como sea, el 'caucho' o 'cahuchu', como le llamaban los aborígenes, que quiere decir ‘madera que llora’, abrió el camino trágico de miles de indígenas cuando al Gran Putumayo, hoy Amazonas, llegó la Casa Arana, en 1901, con el comerciante peruano Julio César Arana, y que en 1907 se transformó en Peruvian Amazon Rubber Company.
            
            
            Sus atrocidades están plenamente documentadas, pero son las palabras de los descendientes de quienes sobrevivieron las que más pesan. En La Chorrera, donde estuvo el centro de operación de los Arana, ahora hay una escuela y allí Adolo Pablo Jacobombaira, un Uitoto, sana heridas.
            
            
            Catarsis
            “Soy del Clan del Loro y hablo el Búnetsa. Nosotros venimos de la cabecera del Cahuanarí, de aquí a casi 80 kilómetros. Llegamos en tiempos diferentes por la necesidad de escolarizarnos y estamos acá hace más o menos 60 años porque es una necesidad, digamos política, económica y social también”.
            
            Lleva casi 28 años en el magisterio, es magister en educación y hoy, además de su profesión de docente, también está en la chagra “trabajando la tierra”, la mayor vocación del indígena. “Tengo cuatro hijos; dos mujeres y dos varones, dos nietas y a mi esposa Odilia. Mis papás todavía están vivos”, aclara, un dato valioso a la luz de su historia.
            
            Aunque su relato es un capítulo que él y su etnia han decidido dejar atrás para poder avanzar, hace una salvedad: “Quiero hacer conocer que todo esto es importante para la humanidad, para la sociedad nuestra, para los jóvenes y hace parte de la cultura y la tradición. Siempre se debe tener presente, nunca se debe olvidar. Históricamente hemos tenido, como en todas las sociedades, situaciones muy comprometedoras y complicadas con nuestros antepasados. Desde 1900 a 1934 todo lo que ha correspondido a las dos guerras mundiales, nos ha influenciado también acá”.
            
            Y es que, si bien la segunda gran guerra va desde 1939 hasta 1945, la demanda del caucho vivió una segunda etapa porque muchos territorios con cultivos de árboles, de donde se extraía, fueron ocupados en ese entonces y la mirada volvió sobre la Amazonía. “El caucho les sirvió a las dos guerras como materia prima en la elaboración de automóviles, para los aviones y seguramente para otra cosa”, dice Pablo.
            
            “Y existió un genocidio y un etnocidio de casi 4 mil a 5 mil indígenas. Ahorita todo esto es selva, pero antes de 1900 estaba superpoblado, cada grupo étnico que existe hoy aquí son los restos, porque hubo un momento en que nuestra gente se sintió ya muy acabada, casi exterminada. Entonces los abuelos sintieron que se tenía que salvar toda esa tierra y a los grupos humanos. 
            
            `,
        index: "cronr2",
        nextindex: "crona2",
        txtB: "seguir explorando",
        imgbackground: arbol,
      },
      {
        type: "cronaudio",
        titleh: "El valor de las palabras",
        title: "El valor de las palabras",
        logo: smlogo,
        content: ``,
        index: "crona2",
        nextindex: "videoley2",
        txtB: "seguir explorando",
        imgbackground: hablar,
        audio: audiosm2,
      },
      {
        type: "video",
        titleh: "Hijos de la coca, el tabaco y la yuca dulce",
        title: "Hijos de la coca, el tabaco y la yuca dulce",
        logo: sclogo,
        video: "https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:71170424.smil/playlist.m3u8",
        txtB: "seguir explorando",
        index: "videoley2",
        nextindex: "cronacul2",
      },
      {
        type: "cronaudio",
        titleh: "Expresiones culturales",
        title: "Expresiones culturales",
        logo: smlogo,
        content: ``,
        index: "cronacul2",
        nextindex: "cronpira2",
        txtB: "seguir explorando",
        imgbackground: culture,
        audio: audiocul2,
      },
      {
        type: "cronaudio",
        titleh: "Festival Pirarucú",
        title: "Festival Pirarucú",
        logo: smlogo,
        content: `Celebración de las tradiciones y la cultura amazónica`,
        index: "cronpira2",
        nextindex: "",
        txtB: "seguir explorando",
        imgbackground: pesca,
        audio: diana2,
      },
    ],
  },
  {
    cap: 3,
    title: "Explotación",
    content: [
      {
        type: "titlecap",
        cap: "3",
        title: "EXPLOTACIÓN DE RECURSOS",
        titleh: "EXPLOTACIÓN DE RECURSOS",
        subtitle: "Destrucción de la Amazonía",
        content: "Considerar a la Amazonía como un supermercado de donde se pueden obtener recursos sin ningún control, ha generado la destrucción de este ecosistema, porque la armonía es la clave para la supervivencia de todas las especies que conviven aquí.",
        imgbackground: explot,
        index: "cap3",
      },
      {
        type: "video",
        titleh: "Los Colonos",
        title: "Los Colonos",
        logo: sclogo,
        video: videoex2,
        imgpre: mult4,
        txtB: "seguir explorando",
        index: "videoex2",
        nextindex: "videoex1",
      },
      {
        type: "video",
        titleh: "La conquista de la amazonia",
        title: "La conquista de la amazonia",
        logo: sclogo,
        video: videoex1,
        imgpre: mult4,
        txtB: "seguir explorando",
        index: "videoex1",
        nextindex: "colonos3",
      },
      {
        type: "cronaudio",
        titleh: "Los Colonos Podcast",
        title: "Los Colonos",
        logo: smlogo,
        content: `La compleja colonización mestiza y campesina del Amazonas también nos 
        ha dejado historias de vida que hacen parte de nuestra herencia cultural.`,
        index: "colonos3",
        nextindex: "audioasen",
        txtB: "seguir explorando",
        imgbackground: habselva,
        audio: audiosm3,
      },
      {
        type: "cronaudio",
        titleh: "Llegada y asentamiento de las comunidades campesinas",
        title: "Llegada y asentamiento de las comunidades campesinas",
        logo: radional,
        content: ``,
        index: "audioasen",
        nextindex: "croncas3",
        txtB: "seguir explorando",
        imgbackground: habselva,
        audio: audioasen,
      },
      {
        type: "cronreading",
        titleh: '"La casa común"',
        title: "ENCUENTRO CON LOS ABUELOS EN ‘LA CASA COMÚN’",
        subtitle: '"La casa común"',
        doc: casadoc,
        content: `
        Cerca de Mitú está la comunidad Timbó de Betania, desde donde se accede a un lugar donde los cerros parecen reunirse para vigilar la selva que se extiende en todas direcciones. Una vista sobrecogedora que inunda los ojos con su belleza y un tesoro amenazado por la minería.
        La naturaleza parece cerrar filas en una reunión de potestades y espíritus ancestrales que brindan un espectáculo solo digno de la Amazonía. Las gentes de Timbó de Betania visitan la cima de uno de ellos, Cerro Hamaca, donde entran en comunión con los 'abuelos' y montan guardia porque saben que los cerros, que son las 'casas sagradas', están amenazados.
        Cerro Hamaca cerca de la comunidad Timbó de Betania, ubicada a 50 kilómetros de Mitú que se corren en dos horas y media a buena marcha por una vía angosta. Allí hay un resguardo que reúne 32 familias de varias etnias, una de cuyas autoridades es su presidente Rubén Darío Arcila Montalvo, quien en el pasado también fue capitán de la comunidad.
        Él explica que las familias pertenecen a siete pueblos indígenas: Tucano, Siriano, Guanano, Bará, Tatuyo, Cubeo y Guarano, que hacen parte de los 27 pueblos que hay en el Vaupés. “A cada pueblo le corresponde un territorio y el de Timbó es de los Yurutí”.
        Timbó es el nombre del caño que pasa por allí y que en idioma yeral significa “Caño Barbasco”, y Betania es el nombre de la comunidad que se lo dieron los curas Monforthianos, cuenta rubén. Allí, a unos cien metros, está Caño Timbó que, como muchos caños y ríos en el Vaupés, es de aguas negras teñidas de un color ambar, y de donde se surte de agua la comunidad y los niños y grandes se refrescan en las tardes en un paisaje paradisiaco.
        Esta es parte de la riqueza natural que se reúne en este lugar, además de sus danzas, las artesanías y un aspecto que recientemente le ha traído preocupación: la riqueza mineral cuando, en 2017, apareció un funcionario de una empresa con un documento en mano pidiéndole a Rubén que firmara. Era una concesión por 30 años para explotar coltán en Cerro Abejorro, a lo cual por supuesto se negó, pues además se había irrespetado el derecho de consulta previa de tres comunidadesd, la suya, la de Bogotá Cachivera y la de Murutinga.
        La visita alteró la paz de la comunidad y desde entonces el temor ronda ante la posibilidad de que la minería acabe con sus recursos naturales, profane el territorio de los animales, las creencias, costumbres, cultura y la herencia territorial y ancestral.
        Ese interés minero no es casual, el Vaupés es un territorio rico en minerales como Niobio, Tantalio, Vanadio y Circonio, o Tierras Negras, y otros van tras el petróleo y el oro.
        Compartiendo conocimiento
        Partiendo de Timbó de Betania por entre la selva, camino a Cerro Hamaca en un trayecto de 30 minutos por la selva desde el caserío, Rubén Darío habla del importancia de los saberes y el conocimiento de un líder. 
        Precisamente, fue nombrado presidente por el valor que la comunidad le da a su conocimiento, su experiencia como dirigente y el trabajo que ha realizado como autoridad tradicional. “Se necesita conocer la cultura, las tradiciones, la danza y saber plantear proyectos”, dice.
        Viene a su memoria el día en que partió su padre, quien fue sabedor de la comunidad, y antes de morír tuvo unas palabras para él: "Me dijo hijo, en este momento estoy dejando, pero la visión que  tengo es que usted va a ser un gran líder. Ese día la gente lo va a respetar". Dice que así se cumplió, fue nombrado presidente de acción comunal, trabajó con el Sena en investigaciones, fue a Yopal, Casanare donde expuso sobre variedades de una semilla, ocupó el primer puesto.
        El director del Sena en esa población lo invitó a diseñar aplicando su conocimiento en la fabricación de artesanías, él le pidió los materiales y frente a trescientos alumnos y algunos docentes estuvo dos días compartiendo su saber. "Me decían 'El hombre araña' porque tejo muy rápido", cuenta. "Pero no es por la habilidad como tal, es por el rezo, mi papá en un solo día sacaba tres matafríos (especie de colador para exprimir la yuca brava)". Ahora enseña a los niños y dice que  ya hay dos maestros que fueron sus alumnos y que le superan. 
        En donde los ojos del forastero no ve sino paisaje, Rubén detecta una planta que provee un jabón natural, más adelante otra cuya fibra sirve para fabricar cernidores para la yuca brava. Sabe en dónde poner sus pies a cada paso, transita por las chagras de un hermano suyo donde cultiva plátano y yuca y llega a un viejo cambuche que sirvió de refugio durante la pandemia provocada por el COVID 19, cuando debían aislarse.
        Entonces viene a su memoria un episodio con un tigre. “Aquí estaba mi hermano una noche con la esposa recogiendo y limpiando yuca”, dice mientras señala un punto cerca de las cenizas de una fogata, “y de repente de la nada salió un tigre que atacó a uno de los perros, lo mató y se lo llevó”. Todo ocurrió a escasos tres metros de su hermano. “Y eso no es todo -sonríe- al día siguiente volvió y mató otro perro”.
        Es natural para las comunidades de la Amazonía que en las épocas de subienda los felinos transiten cerca, en especial en las noches. Llegando al pie del cerro, avanza y desciende hasta encontrar un lugar sagrado: ‘La Cueva del Diablo’. "Por ella se puede atravesar el cerro, pero hoy no, es peligroso”, dice.
        Una visión de ensueño bajo amenaza
        Y en la cima de Cerro Hamaca ese temor por que la minería llegue a estos lugares, se justifica: la vista corta la respiración. “Esto no es un cerro, es una maloca. Aquí habitaban los seres vivientes de la naturaleza que hoy denominan ‘Diablos’. En el pasado, cuando se hizo daño al conocimiento, la sabiduría y la espiritualidad, nos cerraron las puertas y por eso los seres vivientes de este lugar se trasladaron al ‘Cerro Bastón’. Desde ese momento solo hay un dueño, el abuelo de la maloca”.
        Por esa razón antes de llegar al cerro Rubén pidió permiso para que los visitantes fueran bien recibidos y no corrieran ningún peligro. Arriba Cerro Hamaca se convierte en un mirador en donde los cerros aparecen uno tras otro en un giro de 360 grados, como escoltando a la selva separados por kilómetros de distancia: Cerro Abejorro, sobre el que se cierne la amenaza de la minería; Cerro de los Huesos, que es de Tucandía; Cerro Tipiaca, que pertenece a Villa Fátima; Cerro Cuya, que pertenece a Monforth, y Cerro Bastón, de la comunidad de Bogotá Cachivera.
        “Aquí uno siente como si viviera en un paraíso, no hay problemas, es la tranquilidad que tenemos acá los pueblos indígenas. No sentimos lo que sienten otros en otras partes con la guerra y las amenazas, convivimos con otros pueblos y así vivimos en paz”.
        Su orgullo es plenamente justificado, no solo por poder visitar ese tesoro desde su comunidad, sino porque está dispuesto a protegerlo a costa de todo de quienes no sienten aprecio por él y quieren alterarlo. “Ellos no piensan igual que nosotros, sólo piensan en venir a destruir, a abrir 30, 40 o más hectáreas y tumbar la naturaleza. Es por esto que cuidamos esta Amazonía colombiana, porque es ‘La casa común’.
        `,
        index: "croncas3",
        nextindex: "mercu3",
        txtB: "seguir explorando",
        imgbackground: explot6,
      },
      {
        type: "video",
        titleh: "Ríos de Mercurio",
        title: "Ríos de Mercurio",
        logo: sclogo,
        video: videoex5,
        imgpre: "",
        txtB: "seguir explorando",
        index: "mercu3",
        nextindex: "dueno3",
      },
      {
        type: "video",
        titleh: "No somos dueños",
        title: "No somos dueños",
        logo: sclogo,
        video: videoex4,
        imgpre: "",
        txtB: "seguir explorando",
        index: "dueno3",
        nextindex: "videomultinoti",
      },
       {
        type: "videomulti",
        titleh: "Especial Noticias",
        title: "Especial Noticias",
        logo: sclogo,
        video1:
          "https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:22170424.smil/playlist.m3u8",
        video2:
          "https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:21170424.smil/playlist.m3u8",
        imgpre: "",
        txtB: "seguir explorando",
        index: "videomultinoti",
        nextindex: "tip3",
      },
      {
        type: "tip",
        titleh: "Sabías que",
        title: "Sabías que",
        content1:
          "La explotación de recursos de la Amazonía comenzó hace más de cuatro siglos cuando llegaron los colonizadores europeos.",
        content2:
          "Entre el 2013 y 2021, la Amazonía colombiana perdió casi un millón de hectáreas de bosque.",
        content3:
          "Deforestar una hectárea cuesta aproximadamente un millón de pesos colombianos.",
        index: "tip3",
      },
    ],
  },
  {
    cap: 4,
    title: "Ciudades",
    content: [
      {
        type: "titlecap2",
        cap: "4",
        title: "LAS CIUDADES",
        titleh: "LAS CIUDADES",
        subtitle: "Interconexión desconocida",
        imgbackground: ciudad2,
        pos: 0,
        index: "cap4",
      },
      {
        type: "video",
        titleh: "Como por arte de magia",
        title: "Como por arte de magia ",
        video:
          "https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:1040424.smil/playlist.m3u8",
        imgpre: "",
        text: `La energía necesaria para usar dispositivos electrónicos, el agua para el consumo diario, 
        y hasta los cambios de la temperatura en las ciudades, tienen mucho que ver con la vida de las aves, 
        los peces, las ceibas, las hormigas, y todas las otras especies de la Amazonía.`,
        txtB: "seguir explorando",
        index: "magia4",
        nextindex: "puerton",
      },
      {
        type: "cronreading",
        titleh: "“En armonia con la selva”",
        title: "Puerto Nariño",
        subtitle: "“En armonia con la selva”",
        doc: puertonardoc,
        content: `
        En contraposición a la capital Leticia, este municipio se la juega por la sostenibilidad. El que llega debe adaptarse a un pueblo sin carros, donde se le hace la guerra al plástico y se vela por iniciativas que acogen el manejo de las basuras, la pesca controlada y el uso racional de los recursos, pero que, como todos en la selva, recibe la influencia persistente del mundo occidental.
        Si hay una población que se la juegue por respetar la selva en la Amazonía, esa es Puerto Nariño, en el departamento del Amazonas, una reputación bien ganada, así como su título de ‘Pesebre Natural de Colombia’, por su aspecto coqueto y organizado.
        Sus casas de madera pintada de colores, sus calles empinadas y con adoquines y sus fachadas en madera con jardines arborizados, van de la mano con políticas de protección del medio ambiente como sólo permitir el tránsito de dos vehículos a combustión, el tractor que recoge las basuras y la ambulancia.
        El reciclaje y la adecuada disposición de residuos es casi un sacramento, se promueve el bajo consumo de energía eléctrica y la protección y embellecimiento del entorno. Dany Monsalve, oriundo de Medellín, es el conductor del tractor, vive hace 11 años en Puerto Nariño y siendo maestro de construcción llegó a Leticia para edificar una bodega y un hotel, pero allí conoció a la que hoy es su esposa, tuvieron una hija y se fue a Puerto Nariño. La Amazonía está llena de historias como la suya, de visitantes de todas las latitudes que fueron adoptados.
        “Me gustó la tranquilidad y la armonía de las personas, es gente muy bonita y colaboradora y tengo compañeros muy buenos y elegantes”, dice en su marcado acento, mientras se detiene para que sus ayudantes recolecten los residuos y las basuras orgánicas, que luego se manejan para otros procesos, como fabricación de abono.
        Ellos los seleccionan y los depositan en el relleno sanitario, distante a unos unos 15 minutos desde el casco urbano que reúne a la Alcaldía y entidades municipales, hoteles y comercio, donde la gente saca la basura día de por medio, pero el tractor recorre las calles por los ocho sectores de la población a diario, un día cuatro y al siguiente los otro cuatro, de 7 a.m. a 5 p.m.
        Apuesta por el compostaje; en lucha con el plástico
        La Empresa de Servicios Públicos entró en funcionamiento hace poco más de un año y se encargó de implementar todo para estimular la separación en la fuente, acorde a la normatividad: verde, para depositar residuos biodegradables; blanco, para reciclables y lo que se considera como posconsumo; y negro para lo que se descarta o no es aprovechable.
        “Estamos intentando hacer un aprovechamiento con el residuo biodegradable y hemos construido una cajonera donde se dispone y, en coordinación con la Umata, se realiza compostaje para obtener abono”, explica William Martínez, gerente de la ESP de Puerto Nariño.
        La intención es regalarle este material a quienes tengan chagras en la zona para que lo puedan utilizar en sus cultivos y así reducir la cantidad de basuras que se producen en las actividades diarias del municipio en una cantidad importante, especialmente en locales de comercio, de ese modo se deposita menos en la celda y se le da buen uso.
        “Con esto se busca no disponer de residuos orgánicos o residuos biodegradable en la selva. Entre tanto, con los reciclables inicialmente se hace separación de botellas y se entregan a la única empresa de reciclaje autorizada y con licencia en Amazonas, ubicada en Leticia”.
        Según el funcionario, actualmente se adelanta un proceso con CorpoAmazonía para definir qué se hace con el resto del material plástico y se han hecho jornadas de recolección de aparatos eléctricos y electrónicos, baterías, bombillos, medicamentos vencidos, etc, para llevarlos a Leticia, donde se ha organizado su disposición junto a otras empresas.
        También se avanza en la construcción de un nuevo relleno sanitario con todas las características técnicas, con la colaboración de la Gobernación del Amazonas, dado que la celda transitoria que se tiene está próxima a cumplir su vida útil.
        La tendencia mundial es intentar utilizar mejor las basuras, no solo disponerlas en un relleno, que es lo tradicional, sino ir evolucionando, reciclar, aprovechar y emplear alguna tecnología que permita reducir las cantidades de residuos; "Reutilizar, cuando sea posible hacer un mejor uso de las cosas, y Reciclar, cuando se puede hacer algún aprovechamiento, bien sea con las botellas o bien con el material orgánico”.
        El agua, otra parte de la ecuación 
        El uso del plástico es una de las mayores preocupaciones, pues si no se separa en la fuente, hacerlo en el relleno implica más personal y tiempo. Por eso tamabién desde 2022 se estimula a la gente para que lo haga en tres grupos: reciclables, biodegradables y descartables o no aprovechables.
        Si se recibe sólo el material que no es aprovechable, este va directamente a la celda de disposición en el relleno, mientras a los otros se les hace un proceso. También se dispuso un contenedor donado por CorpoAmazonía para que la gente deposite todas las botellas plásticas, así como se les invita a llevar a la ESP las pilas y a la Umata los residuos de aceites. 
        En cuanto al agua y alcantarillado, actualmente se cuenta con un sistema de suministro domiciliario que provee agua para uso doméstico y sanitario en los hogares. “Ya tenemos un trecho recorrido para empezar a proveer al municipio de una planta nueva que nos garantice agua potable domiciliaria. Al mismo tiempo se trabaja en el suministro de líquido apto para el consumo en dos pilas, una cerca del hospital y otra que se va a instalar en el Mirador Naipata”.
        El hecho de que Puerto Nariño sea una comunidad más rural, sin vehículos, cercano a fuentes hídricas y a la naturaleza, crea en la gente la motivación de querer conservar sus riquezas, pero lógicamente esto también supone un balance con el desarrollo de cualquier sociedad, pues la población no es ajena a ese deseo de avanzar que tiene cualquier localidad.
        La administración y la ESP velan porque se apropia desde la niñez esa vocación por cuidar el medio ambiente y por esto trabajan con una juventud que cada vez se aleja más de sus tradiciones seducida por el estilo de vida de Occidente. Y en Puerto Nariño, donde el turismo es la actividad principal, la población flotante aumenta y muchos de los que llegan quieren quedarse, por eso todos se deben meter en el cuento si quieren aminorar el impacto del ‘mundo civilizado’. 
        `,
        index: "puerton",
        nextindex: "videorecicla",
        txtB: "seguir explorando",
        imgbackground: ciudad4,
      },
      {
        type: "video",
        titleh: "Reciclar basuras",
        title: "Reciclar basuras",
        video:
          "https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:2040424.smil/playlist.m3u8",
        imgpre: "",
        txtB: "seguir explorando",
        index: "videorecicla",
        nextindex: "videoconagua",
      },
      {
        type: "video",
        titleh: "Consumo de agua",
        title: "Consumo de agua",
        video:
          "https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:1V040424.smil/playlist.m3u8",
        imgpre: "",
        txtB: "seguir explorando",
        index: "videoconagua",
        nextindex: "videomulticiudad",
      },
      {
        type: "videomulti",
        titleh: "Descubre los ríos de la Amazonía",
        title: "",
        logo: sclogo,
        video1:
          "https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:4040424.smil/playlist.m3u8",
        video2:
          "https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:5V040424.smil/playlist.m3u8",
        imgpre: "",
        txtB: "seguir explorando",
        index: "videomulticiudad",
        nextindex: "regtemp",
      },
      {
        type: "cronaudio",
        titleh: "Regulador de temperatura para las ciudades",
        title: "Regulador de temperatura para las ciudades",
        logo: radional,
        content: ``,
        index: "regtemp",
        nextindex: "mitu",
        txtB: "seguir explorando",
        imgbackground: ciudad3,
        audio: ciudadpod1,
      },
      {
        type: "cronaudio",
        titleh: "CALLES DE MITÚ",
        title: "Habitar la selva",
        logo: smlogo,
        content: ``,
        index: "mitu",
        nextindex: "tipcap4",
        txtB: "seguir explorando",
        imgbackground: ciudad1,
        audio: ciudadpod2,
      },
      {
        type: "tip",
        titleh: "Sabías que",
        title: "Sabías que",
        content1:
          "A. Los gases de efecto invernadero también se producen por el uso de fertilizantes, las basuras… y hasta nuestra propia respiración.",
        content2:
          "B. La tala indiscriminada de bosque amazónico impacta negativamente en el suministro de agua en las ciudades.",
        content3: "",
        index: "tipcap4",
      },
    ],
  },
  {
    cap: 5,
    title: "Gobernanza",
    content: [
      {
        type: "titlecap",
        cap: "5",
        title: "MARCOS LEGISLATIVOS",
        titleh: "TERRITORIO",
        content: "La Amazonía tiene los mismos derechos que una persona, hay que respetarle su vida, garantizarle su salud, el bienestar y muchas cosas más. Las comunidades indígenas que conviven allí la conocen mejor que nadie, y buscan cada vez mayor autonomía para decidir lo mejor para su territorio y para ellas mismas.",
        subtitle: "Territorio indígena",
        imgbackground: juris1,
        index: "cap5",
      },
      {
        type: "video",
        titleh: "La Educación como Resistencia",
        title: "La Educación como Resistencia",
        video:
          "https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:15040424.smil/playlist.m3u8",
        imgpre: "",
        txtB: "seguir explorando",
        index: "educacion5",
        nextindex: "alcolegio5",
      },
      {
        type: "cronreading",
        titleh: "LLEVANDO LOS NIÑOS AL COLEGIO",
        title: "LLEVANDO LOS NIÑOS AL COLEGIO",
        subtitle: "LLEVANDO LOS NIÑOS AL COLEGIO… EN LA SELVA",
        doc: colegiodoc,
        content: `
        En Mitú el desarraigo amenaza con mandar al olvido la ancestralidad. Por donde se mire hay una historia de familias que se han separado de sus hijos para darles educación o simplemente porque la falta de recursos los obliga a vivir separados para buscar el sustento.
        Incluso un padre de familia tiene que realizar tantos oficios como el día se lo permita para sostener a los suyos, como Hernán Perdomo, quien podría resumirlos en uno solo: todero rebuscador. “Soy agricultor, cazador, fabricador de artesanías y canoas pequeñas (potrillo), grandes (bongo), Canoa de 14 metros he elaborado”, dice en un español que intenta dominar.
        Ha aprendido a masticar el español como muchos y como puede, repitiendo preguntas y uniéndolas a las respuestas, pero se hace entender, pero lo que para otros parece una deficiencia, en realidad es un enorme logro, aprender otra lengua. “Transporto por el río a 6, 8 horas, en motor 15, a veces me ha ido bien, a veces el tiempo no me permite llevar cositas por ahí o pasajeros”.
        El indígena tumba, limpia la chagra, siembra, cosecha y pide permiso a la selva para tomar prestado otro pedazo que le dé el sustento, al tiempo que trata de integrarse a una sociedad occidental que lo absorbe contra su voluntad y no perder sus tradiciones y su cultura.
        “Pertenezco a alguna comunidad”, dice repitiendo la pregunta. “Mi comunidad se llama un lugar Puerto Betel, es un lugar lejano a siete horas al motor viajando, he gastado diez galoncitos de gasolina para poder llegar. Vengo a Mitú de vez en vez, cuando tengo la posibilidad de traer unas cositas para vender o para conseguir algo para los estudiantes y para la casa, a veces uno se vara cuando vive retirado del pueblo”.
        ‘Los estudiantes’ son sus hijos, están en un internado y va a Mitú a verlos, pero para eso no puede ir con las manos vacías, trata de llevar plátano, gallina, pollo, yuca, maíz o lo que pueda cultivar en la chagra, o llevar pasajeros para recoger dinero, llevarles lo que necesiten y lo que él ya no tiene en la maloca. Por eso sus viajes río abajo y río arriba y en ese “de vez en cuando” puede ver que los pequeños estén bien, que tengan lo necesario y abrazarlos antes de ausentarse de nuevo.
        Luchando contra el río Vaupés
        Navegar por el río Vaupés y sus afluentes llevando carga y pasajeros de las comunidades es una tarea ardua. Muchos maquinistas que transportan productos pueden tardar horas, días y hasta semanas para llegar de un lugar a otro, según sea en lancha, canoa a remo o barcazas que pueden llevar entre 70 y 110 toneladas de mercancía.
        Pero no todo es calmo, hay puntos en donde esperan furiosos raudales y cachiveras o caídas de agua que obligan a detenerse, caminar por trayectos y volver a embarcarse más adelante. Si es una canoa los productos se bajan y se cargan más adelante, en el punto hasta donde se arrastre la canoa. Pero las embarcaciones grandes solo pueden llegar a cierto punto, descargar y volver a cargar a otro bote y de resto solo las avionetas permiten llegar a las comunidades más lejanas.
        “El clima no siempre ayuda. En invierno uno se transporta fácil, la vía fluvial está inundada y es posible pasar sin problema, pero en verano, por ejemplo en el caño Curuyarí, hay como seis cachiveras y es muy peligroso, si uno se mete ahí es como querer morir. Entonces todos van por el suelo y uno arrastra la canoa, hay que tirarle un lazo grande, por ahí de 20 metros, halarla y así uno va pasando. Sin embargo a veces llueve durante el viaje, se mojan las cosas para vender y muchas se pueden perder”.
        Ese paso puede tomar una hora u hora y media, según lo que se lleve en la canoa, racimos de plátano, maíz, bultos de farinha y otros productos y cuando los indígenas llegan de sus comunidades a Mitú a veces deben quedarse varios días mientras venden los productos en el mercado de ‘Chapinero’ y luego compran lo que necesitan.
        “A veces uno trae 50 racimos de plátano o 50 o 100 kilos de carne, entonces para negociar hay que demorarse al menos tres días para vender y luego para conseguir algunas cositas como jabón, pilas y sal. Pueden pasar dos o tres días vendiendo sus productos, recogiendo dinero y cuadrando lo del pasaje y hay gente que viene de muy lejos, por ejemplo, del parque del Querarí. Hay trayectos que demoran siete horas a pie o para llegar a algunas comunidades son tres, cinco o hasta ocho días”.
        Hernán habla de otro lugar llamado Papunagua, fronterizo entre Guainía y Vaupés, del que calcula que a la gente le toma 15 días llegar a Mitú, a veces con la familia, a veces solos. Por eso, si es para ir a visitar a los hijos en internados, es un sacrificio que sólo un padre supera.
        “Uno tiene como papá y mamá a los chinos estudiando, la ley exige que se les dé ¿cómo dicen?... educación formal. Entonces tiene que dejarlo en una institución y cuando tenga la posibilidad, venir a visitarlos. Mis dos niños están en el colegio de San Javier Curuyarí y a mis hijitos les he garantizado las cositas básicas, jaboncito, cremita, los útiles escolares que uno más o menos calcula que para tal tiempo se les van a fracasar. Y como papá y mamá agricultores, uno vuelve a rebuscar algo para regresar. Prácticamente yo he visitado cada 15 días a los estudiantes”.
        Duro, un sistema educativo que ni siquiera se adapta a sus creencias y costumbres. Por eso, el desarraigo empieza desde niños, “porque están como muy solitos, pero allá donde nosotros estamos no hay colegios, es pura selva”.
        Y allá, solo con su esposa, Hernán regresa a sembrar y a adentrarse en la espesura en las noches para cazar una danta, una lapa o lo que pueda. Pero, a pesar de las dificultades, dice que para él la Amazonía es todo. “Aquí uno puede tener buena vida, buena salud, buena vivienda, no se le presenta cualquier enfermedad. Entonces, pues gozarla de una manera positiva, tener su espacio adecuado, su alimento suficiente, vivir bien y encontrarle una parte de tranquilidad y de armonía”.
        `,
        index: "alcolegio5",
        nextindex: "derechos5",
        txtB: "seguir explorando",
        imgbackground: juris2,
      },
      {
        type: "video",
        titleh: "Línea de tiempo derechos",
        title: "Línea de tiempo derechos",
        video:
          "https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:25040424.smil/playlist.m3u8",
        imgpre: "",
        txtB: "seguir explorando",
        index: "derechos5",
        nextindex: "putumayo5",
      },
      {
        type: "video",
        titleh: "Predio Putumayo",
        title: "Predio Putumayo",
        video:
          "https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:35040424.smil/playlist.m3u8",
        imgpre: "",
        txtB: "seguir explorando",
        index: "putumayo5",
        nextindex: "enrique5",
      },
      {
        type: "video",
        titleh: "Enrique y Esteban",
        title: "Enrique y Esteban",
        video:
          "https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:45040424.smil/playlist.m3u8",
        imgpre: "",
        txtB: "seguir explorando",
        index: "enrique5",
        nextindex: "juri5",
      },
      {
        type: "cronaudio",
        titleh: "Reconomiento de la jurisdicción indigena ",
        title: "Reconomiento de la jurisdicción indigena ",
        logo: radional,
        content: "",
        index: "juri5",
        nextindex: "tipcap5",
        txtB: "seguir explorando",
        imgbackground: juris3,
        audio: jurispod,
      },
      {
        type: "tip",
        titleh: "Sabías que",
        title: "Sabías que",
        content1:
          "A. En el gobierno de Virgilio Barco se creó el programa de titulación de resguardos, que restituyó más de 18 millones de hectáreas a las comunidades indígenas.",
        content2:
          "B. En el artículo 7 de la Constitución de 1991, se reconoció, por primera vez en la historia de nuestro país, la diversidad étnica y la responsabilidad que tiene el Estado de protegerla.",
        content3:
          "C. Pasaron más de 27 años para que se reconocieran los gobiernos indígenas, y su autonomía para administrar y ejecutar los recursos públicos y privados.",
        index: "tipcap5",
      },
    ],
  },
  {
    cap: 6,
    title: "Compromisos",
    content: [
      {
        type: "titlecap2",
        cap: "6",
        title: "Compromisos",
        titleh: "Busqueda de la armonia",
        subtitle: "Busqueda de la armonia",
        content: "Recuperar y proteger a la Amazonía es un reto que necesita de la unión de esfuerzos entre las comunidades indígenas de ese territorio y las personas que viven en las ciudades, pasando de las palabras a las acciones concretas.",
        imgbackground: sol3,
        pos: 0,
        index: "cap6",
      },
      {
        type: "video",
        titleh: "Bioeconomia",
        title: "Bioeconomia",
        video:
          "https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:16050424.smil/playlist.m3u8",
        imgpre: "",
        text: ``,
        txtB: "seguir explorando",
        index: "bioeco6",
        nextindex: "diamante6",
      },
      {
        type: "cronreading",
        titleh: "UN DIAMANTE DE LA CONSERVACIÓN",
        title: "UN DIAMANTE DE LA CONSERVACIÓN",
        subtitle: "UN DIAMANTE DE LA CONSERVACIÓN",
        doc: diamantedoc,
        content: `
          De ser una finca ganadera, el Diamante de las Aguas pasó a convertirse en una reserva natural en el San José del Guaviare. La convicción de una familia sobre el daño que esta actividad puede causar los llevó a cambiar de ganado por conservación, investigación y educación ambiental.
          Hace 14 años una conversación familiar llevó a la determinación de acabar con el proyecto que los había llevado a crear un proyecto de cría de reses en una finca a las afueras de San José del Guaviare.
          Suponía dar un giro de 360 grados, pero a los ojos de todos necesario para hacer algo por la conservación ambiental. “Tuve una profesión tan variada que, de haber sido industrial, hoy me dedico netamente a la conservación y hago parte de la Red de Veedurías Ciudadanas Guaviare en Paz, soy veedor ambiental”, cuenta Jairo Sedano Santamaría, cabeza de esa iniciativa.
          ¿Por qué se dio ese cambio tan abrupto? La ganadería, una de las actividades que más daño ecoambiental produce y que se está abriendo paso en la Amazonía gracias a la tala de la selva, era su único objetivo. En el año 2000 la familia adquirió una finca ganadera seducida -dice- por lo único que les gustaba en ese entonces: potreros exclusivos y limpios “porque en ganadería bonito es aquello en lo que solo se puedan ver reses y pastos, la naturaleza no cabe”, sostiene.
          "Pero ese sistema desapareció de nuestras vidas cuando comenzamos a tener charlas sobre la conservación, acompañadas de mucha espiritualidad, hasta llegar a la conclusión de que los territorios no merecen que se les destruya como se hace hoy".
          Ahora El Diamante de las Aguas hace parte de la Reserva Forestal Protectora Nacional Serranía de la Lindosa Angosturas II y, según sus propias palabras, dio paso a una reserva natural de la sociedad civil mucho más exclusiva y pensante acerca de cómo conservar la naturaleza y los valiosos ecosistemas de los territorios de la Amazonía.
          Con su voz ronca y fuerte, Sedano habla con su trancado acento santandereano: “Aquí hay vida y dignidad, ganamos tanto que contamos con energía solar y no se permite la entrada de redes eléctricas. El acueducto llega por un sistema que funciona por gravedad y existe un potencial de caracterización de más de 240 aves, tan importantes como el ‘Gallito de Roca’ del Escudo Guyanés, exclusiva y considerada en amenaza de extinción”.
          Y recita otros nombres, como la ‘Cotinga’, el ‘Paragüero’ y muchas otras que asegura han ido desapareciendo por culpa de la extensión agrícola y ganadera y las quemas que han acabado con muchos animales. Además, prolifera vegetación y flora esencial de la Amazonía colombiana y más del afloramiento rocoso de la serranía.
          Replicando y compartiendo conocimiento
          Entrar al Diamante de las Aguas es exigente desde el mismo camino que se debe transitar porque si bien se trata de motivar el amor por la naturaleza, no es un lugar para todos, solo para el que muestre un genuino interés. "Es intencional", explica Jairo Sedano "porque el lugar en sí mismo es una promesa de biodiversidad y por tal motivo prefiero que el acceso sea agendado".
          Ese interés es especialmente para la investigación y el conocimiento lúdico, pero es consciente de que toda persona tiene no solo el derecho sino la obligación conocer este tedoro ambiental. “La capacidad de carga del lugar no nos importa tanto como la resiliencia que se le quiere dar. Si permitimos el ingreso de personas todos los días, ahuyentamos al miquito o el sadino y muchos animales que hoy son presa del jaguar y los felinos que tiene la serranía”.
          En efecto, allí se han instalado cámaras trampa cuyo monitoreo ha permitido, por ejemplo, grabar un puma, el segundo felino más grande de América, a solo 200 metros del área construida y esto permite saber qué presas hay en este complejo natural y quién las consume.
          Precisamente, la Serranía de la Lindosa hace parte del llamado ‘Corredor del Jaguar’, porque es parte del territorio por donde transita este animal, pero también el puma, el yagouarundí y el ocelote, y ese hábitat debe ser protegido para que no entren en conflicto con el hombre.
          “Hay que preservar estos corredores biológicos. Si no dejamos rondas hídricas con mínimo 30 metros a la redonda para que transiten, y los convertimos en potreros, se acaba una gran cadena de alimentación para que ellos, en particular el jaguar, viva y nos permita vivir con él".
          El Corredor del jaguar va desde Norteamérica, en Estados Unidos, hasta la Patagonia y el felino es un símbolo al que se le está acabando su territorio. Por eso se debe hacer lo posible para que esas ‘carreteras biológicas’ tengan un espacio verde por donde puedan moverse el sadino, la anaconda y otras culebras y animales que hoy mueren pisoteados, insiste.
          El ‘Diamante de las Aguas’ vela por esa conservación, allí el corral del ganado se está usando para construir un mariposario o hay espacio para las meliponas nativas, abejas sin aguijón que producen miel con muchas propiedades medicinales y que polinizadoras por excelencia.
          “Por eso nosotros no aplicamos pesticidas ni matamalezas, solo promovemos vida y protección. Si atentamos contra abejas, orugas y mariposas acabamos la diversidad que nos ayuda a polinizar y conservar. Por eso también reemplazamos un cultivo de más de 150 cerdos, por un vivero, tenemos una tiendita de meliponas y endulzamos con el producto medicinal que da una abeja Angelita, una Trigona o una Boca de Sapo”, dice, como enumerando el gran inventario apícola.
          No por casualidad este lugar es aliado de la Universidad del Bosque desde hace nueve años como salida de campo para el programa de Biología y acogió el proyecto 'Amazonía Mía', de Aula Viva, con Usaid, que capacitó durante un año a instituciones educativas de los núcleos de deforestación del Guaviare que hoy se concentran en 'Barranquillita', 'La Cristalina', 'La Unilla', 'El Capricho' y 'Caño Blanco II', así como a 40 líderes y lideresas sociales del departamento, para un total de 140 personas totalmente inmersas allí en un diplomado que duró 120 horas.
          Es una empresa ecoambiental a la que Jairo, su esposa y hasta sus tres hijos, incluida una menor de 11 años y un sobrino de la misma edad, están entregados con alma, vida y sombrero. Porque el ejemplo empieza por casa. “Ellos son ‘los herederos’ de este proyecto".`,
        index: "diamante6",
        nextindex: "turis5",
        txtB: "seguir explorando",
        imgbackground: sol4,
      },
      {
        type: "videomulti",
        titleh: "Guia Turística",
        title: "",
        logo: "",
        video1:
          "https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:26050424.smil/playlist.m3u8",
        video2:
          "https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:46050424.smil/playlist.m3u8",
        imgpre: "",
        txtB: "seguir explorando",
        index: "turis5",
        nextindex: "cacao6",
      },
      {
        type: "cronreading",
        titleh: "CACAO CON AROMA DE SELVA",
        title: "CACAO CON AROMA DE SELVA",
        subtitle: "CACAO CON AROMA DE SELVA",
        doc: cacaodoc,
        content: `
        En la Amazonía las únicas fuentes de empleo suelen estar en las entidades gubernamentales, los hoteles, restaurantes, el transporte y la guianza turísticos. Pero aquellos que no pueden hallar un trabajo en esas actividades deben vivir de la pesca, la caza y la chagra. Es entonces cuando los proyectos productivos cobran todo su valor.
        Cuando toman forma, hay familias que, gracias a la gestión de instituciones o administraciones municipales, logran emprender, como en Puerto Nariño, donde se teje un esfuerzo conjunto para el beneficio común a partir de la producción de cacao a través de una asociación para la transformación de este fruto, qe integran varias comunidades.
        “Es un cacao nativo con aromas, con el cual se realiza un proceso de transformación para hacer dulces, confites y otras presentaciones”, explica Renijó Marabete, indígena de la Comunidad de San Francisco, presidente de esta organización y quien trabaja en la administración de la planta que se construyó para tal fin.
        “El cacao lo compra la asociación a las comunidades indígenas de la zona, que lo cultivan en sus parcelas o chagras y es ciento por ciento natural, porque en su producción no se emplean químicos ni productos artificiales como fertilizantes, sino que todo es orgánico”.
        Es un producto nativo del Amazonas y él labora en la planta, junto a otras personas, brindando asistencia técnica a las 77 parcelas que hay en Puerto Nariño, incluidas las que están en Nazareth del Río Amazonas, que hace parte de esa zona; y en Mocagua y Macedonia, que se enfocan más en la ribera del río Loretoyacu.
        Renijo es tecnólogo en Gestión Empresarial, pero se enfocó en esta labor como extensionista en manejo fitosanitario, manejo de plagas y enfermedades, fertilizantes y todo lo relacionado con injertos y otros aspectos de la planta.
        “El cacao de esta región cuenta con características propias; es más dulce, tiene más aroma, no es tan ácido y es muy delicioso por su olor y sabor. Con él se están haciendo barras de chocolates y confites con una composición 70 por ciento chocolate y 30 por ciento esencias de dulces con sabor a frutos exóticos como asaí, arazá, copoazú, mandroño, castaño y macambo”.
        La asociación la conforman 11 familias, pero tanto esta como la administración de Puerto Nariño se han propuesto integrarlas a todas y con ese fin van a las comunidades donde se les establece una fecha para comprar la semilla, ya sea en baba o seca. “Ellos nos dicen para qué día la tienen lista y la asociación se encarga de ir y comprarla o bien la gente de las comunidades las lleva a la asociación y allí se les paga el valor en efectivo, que puede estar en 3.000 pesos el kilo de semilla en baba y 7.000 mil el de semilla seca”.

        La planta, que se construyó con el apoyo del Instituto Amazónico de Investigaciones Científicas -Sinchi- no estaba en funcionamiento en un principio debido a que todos en el pueblo tenían sus ocupaciones y trabajos y no había quién se hiciera cargo, pues implicaba permanecer allí todo el tiempo. Entonces la administración municipal, a través de la secretaría de Cultura, contrató a dos técnicos, entre ellos Renijó, para poner en marcha el proyecto. 
        Adicionalmente, sostiene que en la actualidad se adelanta un proyecto para producir también licor de cacao. Incluso el producto ya se ha llevado a una feria en Leticia y en tres ocasiones a otra en Bogotá, mientras que en Puerto Nariño se da a conocer entre los turistas “y ya hay personas que están solicitando comprar en cantidad”, asegura.
        Sin embargo, en la actualidad la secretaría de Cultura de Puerto Nariño y a través de la misma planta se hacen ingentes esfuerzos para encontrar aliados que impulsen su comercialización, porque es lo más importante para que se logren los resultados que se buscan.
        “Es un producto que puede llegar a comercializarse muy bien si le damos el empuje y le ponemos el empeño que necesita, pero se requieren impulsores y compradores porque el cacao llega a la planta, pero ¿a dónde lo van a llevar las comunidades si no cuentan con ese apoyo?”, cuestiona el secretario de Cultura del municipio, Alejandro Rivera.
        “El mercado local compra muy poco y el turista viene y compra uno o dos paquetes, o cinco chocolates, pero para que esto sea sostenible debemos tener uno o varios aliados que puedan comprar varias toneladas o nos ayuden a sacar toda la producción”.
        Una iniciativa que bien vale la pena dar a conocer al resto del país, pero que pide a gritos gestores que lo lleven a otros escenarios para que se dé a conocer el cacao con aroma a selva, igual que muchos otros productos que la Amazonía atesora esperando que alguien les dé el valor que tienen.  
        `,
        index: "cacao6",
        nextindex: "videoyare6",
        txtB: "seguir explorando",
        imgbackground: sol1,
      },
      {
        type: "video",
        titleh: "Sombreros de Yaré",
        title: "Sombreros de Yaré",
        video:
          "https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:36050424.smil/playlist.m3u8",
        imgpre: "",
        text: ``,
        txtB: "seguir explorando",
        index: "videoyare6",
        nextindex: "videoter6",
      },
      {
        type: "video",
        titleh: "Mensajes del territorio",
        title: "Mensajes del territorio",
        video:
          "https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:56050424.smil/playlist.m3u8",
        imgpre: "",
        text: ``,
        txtB: "seguir explorando",
        index: "videoter6",
        nextindex: "tipcap6",
      },
      {
        type: "tip",
        titleh: "Sabías que",
        title: "Sabías que",
        content1:
          "- Los términos Bioeconomía y Economía Circular tienen que ver con producir las cosas que necesitamos para vivir, pero siendo amigables con el planeta, aprovechando los recursos al máximo.",
        content2:
          "- Cumare es una palma con gran significado para los indígenas. Y la utilizan para crear diversos productos que les generan ingresos.",
        content3:
          "- El turismo es una buena alternativa para mejorar la economía de la Amazonía, si se hace con responsabilidad y respeto hacia las comunidades que viven en ella.",
        index: "tipcap6",
      },
    ],
  },
];

export default data;
