
import style from "./Social.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { useEffect } from "react";



const Social = ({ color }) => {

  useEffect(()=>{

    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('async',true);
    script.setAttribute('src', 'https://static.addtoany.com/menu/page.js');
    head.appendChild(script);

  })


  return (

    <div className={style.social}>
      <div class="a2a_kit a2a_kit_size_32 a2a_default_style" data-a2a-url="https://www.rtvc.com.co/especiales/respira-amazonia/" data-a2a-title="Respira Amazonía">
        <a style={{color:color}} class="a2a_dd">
          <FontAwesomeIcon icon={faShareNodes} />
        </a>
      </div>
      <a style={{color:color}} href="https://www.radionacional.co/">
        <FontAwesomeIcon icon={faFacebook} />
      </a>
      <a style={{color:color}} href="https://www.radionacional.co/">
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a style={{color:color}} href="https://www.radionacional.co/">
        <FontAwesomeIcon icon={faYoutube} />
      </a>
    </div>

  );
};



export default Social;
