//import logo from './logo.svg';
import './App.css';
import { ParallaxProvider } from "react-scroll-parallax";
import  Parallax  from "./components/Parallax/Parallax"


function App() {
  return (
    <ParallaxProvider>
      <Parallax />
    </ParallaxProvider>
  );
}

export default App;
