//create a funtion component for react component with a image and a title

import React, { useEffect, useState } from "react";
import {
  faMusic,
  faPlay,
  faPause,
  faVolumeHigh,
  faVolumeOff,
  faVolumeLow,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";
import { useMyContext } from "../../context/context";

import style from "./VideoFull.module.scss";
import Button from "../../components/Button/Button";

const VideoFull = ({ data }) => {
  //const [isPlaying, setIsPlaying] = useState(false);
  const [loop, setLoop] = useState(true);
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(1);
  const [showVolume, setShowVolume] = useState(false);
  const [played, setPlayed] = useState(0);
  const [player, setPlayer] = useState({});
  const [seeking, setSeeking] = useState(false);
  const { contextPause, setContextPause } = useMyContext();

  useEffect(() => {
    if (contextPause !== false) {
      if (contextPause === player) {
        setPlaying(true);
        //console.log('VideoFull',player);
      } else {
        setPlaying(false);
      }
      setContextPause(false);
    }
  }, [contextPause]);

  const load = (url) => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false,
    });
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handlePlay = () => {
    setPlaying(true);
    setContextPause(player);
  };

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleToggleMuted = () => {
    setMuted(!muted);
  };

  const handleProgress = (state) => {
    //console.log('onProgress', state)
    // We only want to update time slider if we are not currently seeking
    if (!seeking) {
      setPlayed(state.played);
    }
  };

  const handleSeekChange = (e) => {
    player.seekTo(parseFloat(e.target.value));
  };

  const toggleShow = () => {
    setShowVolume(!showVolume);
  };

  const ref = (player) => {
    setPlayer(player);
  };

  const mediaMatch = window.matchMedia("(max-width: 880px)");
  const [matches, setMatches] = useState(mediaMatch.matches);

  return (
    <div
      className={style.root}
      style={{
        backgroundColor: "#005C72",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "relative",
        width: "100%",
        height: matches ? "200vw" : "50vw",
        zIndex: data.zIndex ? data.zIndex : 0,
      }}
    >
      <ReactPlayer
        className={style.video}
        style={{
          position: "absolute",
          zIndex: "0",
        }}
        ref={ref}
        width={"auto"}
        height="50vw"
        url={data.video}
        loop={loop}
        muted={muted}
        volume={volume}
        playing={playing}
        onPlay={handlePlay}
        onPause={handlePause}
        onProgress={handleProgress}
      ></ReactPlayer>
      <div className={style.body}>
        <div className={style.text}>
          <div className={style.titleCron}>
            <h2>{data.title}</h2>
          </div>
          <div className={style.content}>
            <p>{data.content}</p>
          </div>
          <div className={style.audio}>
            {/*             <div onClick={handleToggleMuted} className={style.audioStick}>
              SONIDO
              <div className={style.pipe}></div>
              <FontAwesomeIcon icon={faMusic} />
            </div> */}
          </div>
          <div className={style.videoControls}>
            <button onClick={handlePlayPause}>
              {playing ? (
                <FontAwesomeIcon icon={faPause} />
              ) : (
                <FontAwesomeIcon icon={faPlay} />
              )}
            </button>
            <div className={style.progressBar}>
              <input
                type="range"
                min={0}
                max={0.999999}
                step="any"
                value={played}
                onChange={handleSeekChange}
              />
            </div>
            <button onClick={() => handleToggleMuted()}>
              {muted || volume < 0.5 ? (
                <FontAwesomeIcon icon={faVolumeOff} />
              ) : volume < 0.4 ? (
                <FontAwesomeIcon icon={faVolumeLow} />
              ) : (
                <FontAwesomeIcon icon={faVolumeHigh} />
              )}
            </button>
          </div>
        </div>
      </div>
      <div>
        <Button to={data.nextindex} text={data.txtB}></Button>
      </div>
    </div>
  );
};

export default VideoFull;
