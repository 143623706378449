import { useRef, useState, useEffect, forwardRef, useCallback } from "react";
import style from "./AudioPlayer.module.scss";
import Controls from "./Controls";
import ProgressBar from "./ProgressBar";
import VolumeControl from "./VolumeControl";
import { useMyContext } from "../../context/context";

const AudioPlayer = forwardRef(
  ({ src, isPlaying, setIsPlaying, muted }, ref) => {
    const [timeProgress, setTimeProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const { contextPause, setContextPause } = useMyContext();
    

    const progressBarRef = useRef();
    const audioRef = useRef();

    useEffect(() => {
      if (contextPause !== false) {
        if (contextPause === audioRef) {
          setIsPlaying(true);
        } else {
          setIsPlaying(false);
        }
        setContextPause(false);
      }
    }, [contextPause]);


    const onLoadedMetadata = () => {
      //console.log(audioRef.current.duration);
      const seconds = audioRef.current.duration;
      setDuration(seconds);
      progressBarRef.current.max = seconds;
    };

    return (
      <div className={style.audioPlayer}>
        <audio src={src} ref={audioRef} onLoadedMetadata={onLoadedMetadata} />
        <Controls
          ref={ref}
          {...{
            audioRef,
            progressBarRef,
            duration,
            setTimeProgress,
            isPlaying,
            setIsPlaying,
          }}
        />
        <ProgressBar
          {...{ progressBarRef, audioRef, timeProgress, duration }}
        />
        <VolumeControl {...{ audioRef, muted }} />
      </div>
    );
  }
);

export default AudioPlayer;
