import React, { useEffect, useState  } from 'react';
import style from "./VideoMulti.module.scss";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideoCamera } from '@fortawesome/free-solid-svg-icons';
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import Carousel from 'react-bootstrap/Carousel';


const VideoMulti = ({ data }) => {


  useEffect(()=>{
    const prev = document.getElementsByClassName('carousel-control-prev');    
    const nxt = document.getElementsByClassName('carousel-control-next'); 

    //console.log('prev', prev)

    [...prev].forEach((item)=>{
      item.setAttribute('href','javascript:void(0)')
      item.setAttribute('style','position:relative; left: 30%; transform: translateY(30px)')
    });

    [...nxt].forEach((element)=>{
      element.setAttribute('href','javascript:void(0)')
      element.setAttribute('style','position:relative; left: 55%')
    });
  });


  const [index, setIndex] = useState(0);
  
    const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
    };



  return (
    <div className={style.root}>
      <div className={style.title}>
        <FontAwesomeIcon icon={faVideoCamera} />
        <h1>{data.title}</h1>
        {data.logo && <div className={style.line}></div>}
        {data.logo && <img className={style.logo} src={data.logo} />}
      </div>

      <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <div className={style.videoContainer}>
            <VideoPlayer video={data.video1} imgpre={data.imgpre} text={data.text}/>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className={style.videoContainer}>
            <VideoPlayer video={data.video2} imgpre={data.imgpre} />
          </div>
        </Carousel.Item>
        {data.video3 &&
        <Carousel.Item>
          <div className={style.videoContainer}>
            <VideoPlayer video={data.video3} imgpre={data.imgpre} />
          </div>
        </Carousel.Item>
        }
      </Carousel>
      <div className={style.button}>
        <Button to={data.nextindex} text={data.txtB}></Button>
      </div>
    </div>
  );
};

export default VideoMulti;