//create a funtion component for react component with a image and a title

import React from "react";
import title from "../../assets/images/png/logos/logo-version-2-horizontal-con-rtvc-y-wwf.png";
import logos from "../../assets/images/png/logos/logos_cabezote.png";

import logoradnal from "../../assets/images/png/logos/logos_cabezote/radnal.png";
import logoplay from "../../assets/images/png/logos/logos_cabezote/rtvcplay.png";
import logoci from "../../assets/images/png/logos/logos_cabezote/ci.png";
import logosc from "../../assets/images/png/logos/logos_cabezote/sc.png";
import logosm from "../../assets/images/png/logos/logos_cabezote/sm.png";
import logorad from "../../assets/images/png/logos/logos_cabezote/radionica.png";

import logosmañana from "../../assets/images/png/logos/smanana_magdalena_footer.png";
import logosnoti from "../../assets/images/png/logos/rtvcnoticia_magdalena_footer.png";
import style from "./Title.module.scss";
import Button from "../../components/Button/Button";
import imgbk from '../../assets/images/images_fondos/otrohome3.jpg';

const Title = () => {
  return (
    <div
      className={style.root}
      style={{
        backgroundImage: `url(${imgbk})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: "relative",
        zIndex: 100,
      }}
    >
      <img className={style.logoTitle} src={title}></img>
      <h1 className={style.title}>LA AMAZONÍA NOS HABLA Y NOS ESCUCHA</h1>
      <div className={style.content}>
        <p>
          Un viaje desde la ciudad hasta corazón de la Amazonía para conocer los
          peligros y amenazas que afectan el medioambiente de esta región,
          redescubrir su biodiversidad, compartir con las comunidades que la
          habitan, comprender la interrelación que este ecosistema tiene con en
          el resto de Colombia y el mundo; y promover acciones positivas para
          reestablecer la armonía de este paraíso natural.
        </p>
      </div>
      <div className={style.logorow}>
        <img className={style.logo2} src={logosmañana}></img>
        <img className={style.logo1} src={logosc}></img>
        <img className={style.logo1} src={logoci}></img>
        <img className={style.logo1} src={logoradnal}></img>
        <img className={style.logo1} src={logorad}></img>
        <img className={style.logo1} src={logosm}></img>
        <img className={style.logo1} src={logoplay}></img>
        <img className={style.logo2} src={logosnoti}></img>
      </div>
      <div className={style.btn}>
        <Button
          to={"videof1"}
          text={"DESPLÁZATE HACIA ABAJO PARA COMENZAR EL VIAJE"}
        ></Button>
      </div>
    </div>
  );
};

export default Title;
