import React from 'react';
import ReactPlayer from 'react-player';

import style from "./Video.module.scss";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideoCamera } from '@fortawesome/free-solid-svg-icons';
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";


const Video = ({ data }) => {
  return (
    <div className={style.root}
      style={{
        position: 'relative',
        zIndex: data.zIndex ? data.zIndex:0
      }}
      >
      <div className={style.title}>
        <FontAwesomeIcon icon={faVideoCamera} />
        <h1>{data.title}</h1>
        {data.logo && <div className={style.line}></div>}
        {data.logo && <img className={style.logo} src={data.logo} />}
      </div>
      <div className={style.videoContainer}>
        <VideoPlayer video={data.video} imgpre={data.imgpre} text={data.text} />
      </div>
      <div className={style.button}>
        <Button to={data.nextindex} text={data.txtB}></Button>
      </div>
    </div>
  );
};

export default Video;