//create a funtion component for react component with a image and a title

import React, { useState } from 'react';
import title from '../../assets/images/png/logos/logo-version-2-horizontal-con-rtvc-y-wwf.png';
import titlemed from '../../assets/images/png/logos/Logoverde.png';
import logobear from '../../assets/images/png/logos/rtvcwwf2.png';

import style from "./Footer.module.scss";
import imgfoot from '../../assets/images/fotos_/fondo_footer.jpeg';

import logoradnal from '../../assets/images/png/logos/logos_cabezote/radnal.png';
import logoplay from '../../assets/images/png/logos/logos_cabezote/rtvcplay.png';
import logoci from '../../assets/images/png/logos/logos_cabezote/ci.png';
import logosc from '../../assets/images/png/logos/logos_cabezote/sc.png';
import logosm from '../../assets/images/png/logos/logos_cabezote/sm.png';
import logorad from '../../assets/images/png/logos/logos_cabezote/radionica.png';

import logosmañana from '../../assets/images/png/logos/smanana_magdalena_footer.png';
import logosnoti from '../../assets/images/png/logos/rtvcnoticia_magdalena_footer.png';

const Footer = () => {

  const [windowWidth, setWindowWidht] = useState(window.innerWidth);
  

  function match() {
    let height = '50vw';

    if(windowWidth < 500){
      height = "200vw"
    }
    if(windowWidth > 500 && windowWidth < 1000){
      height = "100vw"
    }
    if(windowWidth > 1000){
      height = "50vw"
    }

    return height;
  }

  const mediaMatch = window.matchMedia('(max-width: 900px)');
  const [matches, setMatches] = useState(mediaMatch.matches); 

  return (
    <div className={style.root}
    >
      <div
        className={style.bg}
        style={{
          backgroundImage: `url(${imgfoot})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'relative',
          backgroundPositionY: matches ? '0' : '-100px',
          opacity: 0.5,
          height: match(),
        }}>

      </div>
      <div className={style.r}>
        {!matches &&
          <img className={style.logoTitle} src={title} ></img>
        }
        {matches &&
          <div>
            <img className={style.logomed} src={titlemed} ></img>
            <img className={style.logobear} src={logobear} ></img>
          </div>
        }
        <div className={style.logorow}>
          <img className={style.logo2} src={logosmañana} ></img>
          <img className={style.logo1} src={logosc} ></img>
          <img className={style.logo1} src={logoci} ></img>
          <img className={style.logo1} src={logoradnal} ></img>
          <img className={style.logo1} src={logorad} ></img>
          <img className={style.logo1} src={logosm} ></img>
          <img className={style.logo1} src={logoplay} ></img>
          <img className={style.logo2} src={logosnoti} ></img>
        </div>
      </div>
    </div>
  );
};

export default Footer;