import { Link } from "react-scroll";
import style from "./Button.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";


const handleSetActive = () => {
  //console.log("setActive");
}

const Button = ({ to, text }) => {
  return (
    <Link
      activeClass="active"
      to={to}
      smooth={true}
      duration={1500}
      //onSetActive={this.handleSetActive}
    >
      <div className={style.wrapper}>
        <button className={style.btn}>
          {text}
        </button>
        <FontAwesomeIcon icon={faAngleDown} />
      </div>
    </Link>
  );
};



export default Button;
