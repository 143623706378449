//create a funtion component for react component with a image and a title

import React, { useState } from 'react';
import bird from '../../assets/images/png/aves/papagayo3.png';
import plant from '../../assets/images/png/follajes o vegetacion/follaje3.png';
import cloud from '../../assets/images/png/nubes/nube2.png';
import branch from '../../assets/images/png/follajes o vegetacion/ramas1.png';
import style from "./TitleCap.module.scss";
import Social from '../../components/Social/Social';

const TitleCap = ({ data }) => {

  const mediaMatch = window.matchMedia('(max-width: 880px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  return (
    <div className={style.root}
      style={{
        backgroundImage: `url(${data.imgbackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'relative',
        backgroundPositionY: matches ? '0px' :'-250px',
        height: matches ? '200vw': '500px',
      }}>
      <div className={style.titleCap}>
        <div>CAPÍTULO</div>
        <div className={style.line}></div>
        <div>{data.cap}</div>
      </div>
      <div className={style.titleContainer}>
        <img className={style.bird} src={bird} />
        <div className={style.title}>
          <div>{data.title}</div>
          {data.subtitle &&
          <div className={style.subtitle}>
            {data.subtitle}
          </div>
          }
        </div>
        <img className={style.cloud} src={cloud} />
      </div>
      <Social color={'white'}></Social>
      {data.content &&
      <div className={style.content}>
        <div>{data.content}</div>
      </div>
      }
      <img className={style.branch} src={branch} />
    </div>
  );
};

export default TitleCap;