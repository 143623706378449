import React, { useState } from "react";

import style from "./ModalVideo.module.scss";
import Modal, { Styles } from 'react-modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';
import VideoPlayer from "../VideoPlayer/VideoPlayer"; 
//images for all content elements


import data from '../../data/data';
import Social from "../Social/Social";


const ModalVideo = ({img, video}) => {

    const [modalIsOpen, setIsOpen] = useState(false);


    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        setIsOpen(false);
    }


    const ModalStyles: Styles = {
        overlay: {
            zIndex: '100',
        }
    };

    const customModalStyles = {
        overlay: { ...ModalStyles.overlay }
    };

    return (
        <div >
            <div
             className={style.btnModal}
             onClick={openModal}>
                <img src={img}></img>
            </div>
            <div className={style.parentModal}>
                <Modal
                    className={style.modal}
                    style={customModalStyles}
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    contentLabel="Video Modal"
                >
                    <button className={style.closeModal} onClick={closeModal}>
                        <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
                    </button>
                    <div className={style.modalBody}>
                        <div className={style.row}>
                        <VideoPlayer video={video} imgpre={img} />
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};



export default ModalVideo;
