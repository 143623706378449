import React, { useState } from "react";
import { Link } from "react-scroll";

import style from "./MainMenu.module.scss";
import Modal, { Styles } from 'react-modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';

//images for all content elements


import data from '../../data/data';
import Social from "../Social/Social";


const MainMenu = () => {

    const [modalIsOpen, setIsOpen] = useState(false);


    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        setIsOpen(false);
    }


    const ModalStyles: Styles = {
        overlay: {
            zIndex: '100',
        }
    };

    const customModalStyles = {
        overlay: { ...ModalStyles.overlay }
    };

    return (
        <div >
            <button
                className={style.btnModal}
                onClick={openModal}>
                    MENÚ
                {/* <FontAwesomeIcon icon={faBars} ></FontAwesomeIcon> */}
            </button>
            <div className={style.parentModal}>
                <Modal
                    className={style.modal}
                    style={customModalStyles}
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                >
                    <button className={style.closeModal} onClick={closeModal}>
                        <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
                    </button>
                    <div className={style.modalBody}>
                        <h1>CAPÍTULOS</h1>
                        <div className={style.row}>
                            {data.map((cap) => {
                                return (
                                    <div className={style.cap}>
                                        <div className={style.title}>
                                            Capitulo {cap.cap}
                                        </div>
                                        <div className={style.links}>
                                            <h3>{cap.title}</h3>
                                            <ul>
                                                {cap.content.map((item) => {
                                                    return (
                                                        <Link
                                                            activeClass={item.index}
                                                            to={item.index}
                                                            smooth={true}
                                                            duration={1500}>
                                                            <li>
                                                                {item.titleh}
                                                            </li>
                                                        </Link>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                    <div className={style.footer}>
                        <div className={style.credits}>
                            CRÉDITOS
                        </div>
                        <Social color={'white'}></Social>
                    </div>
                </Modal>
            </div>
        </div>
    );
};



export default MainMenu;
