//create a funtion component for react component with a image and a title

import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';

import cap1 from '../../assets/images/fotos_/banner_titulo_capitulo1.jpeg';
import bird from '../../assets/images/png/aves/tucan.png';
import branch1 from '../../assets/images/png/follajes o vegetacion/ramas2.png';
import logo from '../../assets/images/png/logos/Logoverde.png';

import style from "./Tip.module.scss";

const Tip = ({ data }) => {

  const mediaMatch = window.matchMedia('(max-width: 880px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  return (
    <div className={style.root}
      style={{
        backgroundImage: `url(${cap1})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'relative',
        backgroundPositionY: matches? '0':'-100px',
        height: matches ? '200vw': '260px',
      }}>
      <img className={style.branchTop} src={branch1} />
      <div className={style.titleContainer}>
        <div className={style.title}>
          <img className={style.bird} src={bird}></img>
          <div className={style.titlein}>
            <div> TIPS </div>
            <img className={style.logo} src={logo}></img>
          </div>
        </div>
      </div>
      <div className={style.content}>
        <div className={style.subtitle}>
          <div>{data.title}</div>
        </div>
        <p>
          {data.content1}
          <br></br>
          {data.content2}
          <br></br>
          {data.content3}
          <br></br>
        </p>
      </div>
      <img className={style.branchBottom} src={branch1} />
    </div>
  );
};

export default Tip;