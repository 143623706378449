import React, { useState, useEffect } from "react";
import Title from "../../screens/Title/Title";
import Video from "../../screens/Video/Video";
import Audio from "../../screens/Audio/Audio";
import { Element, Link, Events } from "react-scroll";
import Cronica from "../../screens/Cronica/Cronica";
import CronicaR from "../../screens/CronicaR/CronicaR";
import MenuRiver from "../MenuRiver/MenuRiver";
import MyContext from "../../context/context";

import style from "./Parallax.module.scss";
import Modal, { Styles } from 'react-modal';
import TitleCap from "../../screens/TitleCap/TitleCap";
import TitleCap2 from "../../screens/TitleCap2/TitleCap2";
import Tip from "../../screens/Tip/Tip";
import VideoFull from "../../screens/VideoFull/VideoFull";

//images for all content elements

import data from '../../data/data';
import Multimedia from "../../screens/Multimedia/Multimedia";
import Footer from "../../screens/Footer/Footer";
import Trivia from "../../screens/Trivia/Trivia";
import Social from "../Social/Social";
import MainMenu from "../MainMenu/MainMenu";
import CronicaSimple from "../../screens/CronicaSimple/CronicaSimple";
import VideoMulti from "../../screens/VideoMulti/VideoMulti";


const selectScreen = (data) => {
  return (
    {
      'audio':
        < Element name={data.index} >
          <Audio data={data}></Audio>
        </Element >,
      'video':
        < Element name={data.index} >
          <Video data={data}></Video>
        </Element >,
      'videomulti':
        < Element name={data.index} >
          <VideoMulti data={data}></VideoMulti>
        </Element >,
      'videofull':
        < Element name={data.index} >
          <VideoFull data={data}></VideoFull>
        </Element >,
      'titlecap':
        <Element name={data.index}>
          <TitleCap data={data}></TitleCap>
        </Element>,
      'titlecap2':
        <Element name={data.index}>
          <TitleCap2 data={data}></TitleCap2>
        </Element>,
      'tip':
        <Element name={data.index}>
          <Tip data={data}></Tip>
        </Element>,
      'cronaudio':
        <Element name={data.index}>
          <Cronica data={data}></Cronica>
        </Element>,
      'cronsimple':
        <Element name={data.index}>
          <CronicaSimple data={data}></CronicaSimple>
        </Element>,
      'cronreading':
        <Element name={data.index}>
          <CronicaR data={data}></CronicaR>
        </Element>,
      'multimedia':
        <Element name={data.index}>
          <Multimedia data={data}></Multimedia>
        </Element>
    }[data.type]
  )

}

const Parallax = () => {

  const [modalIsOpen, setIsOpen] = useState(false);
  const [contextPause, setContextPause] = useState(false);


  const [windowWidth, setWindowWidht] = useState(window.innerWidth);
  

  function match() {
    let height = '50vw';

    if(windowWidth < 500){
      height = "200vw"
    }
    if(windowWidth > 500 && windowWidth < 1000){
      height = "100vw"
    }
    if(windowWidth > 1000){
      height = "50vw"
    }

    return height;
  }

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }


  const ModalStyles: Styles = {
    overlay: {
      zIndex: '100',
    }
  };

  const customModalStyles = {
    overlay: { ...ModalStyles.overlay }
  };


  useEffect(() => {

  
  },[contextPause]);


  return (
    <div >
      <MainMenu></MainMenu>
      <div style={{
        width: '100%',
        height: match()
      }}>
        <Title></Title>
        < div className={style.menu}>
          <MenuRiver></MenuRiver>
        </div>
        <MyContext.Provider value={{contextPause, setContextPause}}>
        {
          data.map((cap) => {
            return (
              <div>
                {cap.content.map((item) => {
                  return (
                    selectScreen(item)
                  );
                })}
              </div>
            )
          })
        }
        </MyContext.Provider>
        {/* <Trivia></Trivia> */}
        <Footer></Footer>
      </div>
    </div>
  );
};



export default Parallax;
