import { faVolumeHigh, faVolumeLow, faVolumeOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import style from "./AudioPlayer.module.scss";

const VolumeControl = ({ progressBarRef, audioRef, timeProgress, duration, muted }) => {

  const [volume, setVolume] = useState(60);
  const [showVolume, setShowVolume] = useState(false);
  const [muteVolume, setMuteVolume] = useState(false);


  const toggleShow = () => {
    setShowVolume(!showVolume);
  };

  useEffect(() => {
    if (audioRef) {
      audioRef.current.volume = volume / 100;
    }

    //console.log(muted)
    if (muted) {
      setVolume(0)
    }else{
      setVolume(60)
    }
  }, [volume, audioRef, muted]);

  return (
    <div className={style.volumeControl}>
      <button onClick={() => toggleShow()}>
        {muteVolume || volume < 5 ? (
          <FontAwesomeIcon icon={faVolumeOff} />
        ) : volume < 40 ? (
          <FontAwesomeIcon icon={faVolumeLow} />
        ) : (
          <FontAwesomeIcon icon={faVolumeHigh} />
        )}
      </button>
      {showVolume &&
        <input
          type="range"
          min={0}
          max={100}
          value={volume}
          onChange={(e) => setVolume(e.target.value)}
        />
      }
    </div>
  );
};



export default VolumeControl;