import React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';




const TwitterEmbed = () => {

  return (
    // Timeline (with options)
    <div>
      <TwitterTimelineEmbed
        sourceType="profile"
        screenName="RTVCco"
        options={{ 
          height: 400,
          width: 350
        }}
      />
    </div>
  );
}

export default TwitterEmbed;
