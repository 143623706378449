//create a funtion component for react component with a image and a title

import React,{useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRetro, faDownload, faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';

import branch from '../../assets/images/png/follajes o vegetacion/ramas1.png';
import style from "./Multimedia.module.scss";
import Social from '../../components/Social/Social';
import ModalVideo from '../../components/ModalVideo/ModalVideo';

const Multimedia = ({ data }) => {

  const mediaMatch = window.matchMedia('(max-width: 880px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  return (
    <div className={style.root}
      style={{
        backgroundImage: `url(${data.imgbackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'relative',
        backgroundPositionY: '-100px',
        height: matches ? '200vw': '50vw',
      }}>
      <div className={style.titleCap}>
        <h1>{data.title}</h1>
      </div>
      <div className={style.titleContainer}>
        <div className={style.title}>
          <h2>{data.subtitle}</h2>
        </div>
      </div>
      <Social color={'white'}></Social>
      <div className={style.content}>
        <div className={style.link1}>
          <img className={style.img1} src={data.img1}></img>
          <a className={style.dw1} href={data.link1}>
            <p>{data.txt1}</p>
            <FontAwesomeIcon icon={faDownload} />
          </a>
        </div>
        <div className={style.link2}>
          <img className={style.img2} src={data.img2}></img>
          <a className={style.dw2} href={data.link2}>
            <p>{data.txt2}</p>
            <FontAwesomeIcon icon={faDownload} />
          </a>
        </div>
        <div className={style.link3}>
          <ModalVideo img={data.img3} video={data.video}></ModalVideo>
          <a className={style.dw3} href={data.link3}>
            <p>{data.txt3}</p>
            <p>{data.txt4}</p>
            <FontAwesomeIcon icon={faCameraRetro} />
          </a>
        </div>
      </div>

      <img className={style.branch} src={branch} />
    </div>
  );
};

export default Multimedia;