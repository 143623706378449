import style from "./AudioPlayer.module.scss";
import { useEffect, useRef, useCallback } from 'react';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMyContext } from "../../context/context";

const Controls = ({  
    audioRef,
    progressBarRef,
    duration,
    setTimeProgress,
    isPlaying,
    setIsPlaying
  }) => {
    
//const [isPlaying, setIsPlaying] = useState(false);

const playAnimationRef = useRef();
const {contextPause, setContextPause} = useMyContext();

const repeat = useCallback(() => {
    const currentTime = audioRef.current.currentTime;
    setTimeProgress(currentTime);
    progressBarRef.current.value = currentTime;
    progressBarRef.current.style.setProperty(
      '--range-progress',
      `${(progressBarRef.current.value / duration) * 100}%`
    );

  playAnimationRef.current = requestAnimationFrame(repeat);
}, [audioRef, duration, progressBarRef, setTimeProgress]);

useEffect(() => {
  //console.log(audioRef)
    if (isPlaying) {
      audioRef.current.play();
      setContextPause(audioRef);
    } else {
      audioRef.current.pause();
      //cancelAnimationFrame(playAnimationRef.current);
    }
    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [isPlaying, audioRef, repeat]);

const togglePlay = () => {
  setIsPlaying(!isPlaying);
};

  return (
    <div className={style.audioControls}>
        <button onClick={togglePlay}>
          {isPlaying ? <FontAwesomeIcon icon={faPause}/>: <FontAwesomeIcon icon={faPlay}/>}
        </button>
    </div>
  );
};



export default Controls;