import React, { useState } from 'react';
import { InstagramEmbed } from 'react-social-media-embed';

function Instagram() {


  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginRight: '10px'}}>
      <InstagramEmbed url="https://www.instagram.com/p/Cz_sZeDN9aH/" width={350} height={400} />
    </div>

  );
}

export default Instagram;
